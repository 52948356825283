import React from 'react';
import stylesPlain from './plain.module.css';

import FieldList from './FieldList';

import { getDefinedValue, getDefinedItem, getCurrentResource } from '../../util/tools';
import { getLanguage } from '../../util/localStorage';
import { ENUMERATION_LANGUAGE } from '../../util/consts/common';
import { IET_ADD_LIST_ITEM } from '../../util/realm/instance';

export const HEADER_POSITION_TOP = 'top;'
export const HEADER_POSITION_RIGHT = 'right;'
export const HEADER_POSITION_LEFT = 'left;'

class FieldList_MultilangString extends FieldList {

	enumeration = null;
	resource = null;
	headerPosition = null; // default - top

	/*
		styles
			divMain
	*/
	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.plain.FieldList_MultilangString;
		this.enumeration = this.props.plain.realm.getEnumeration( ENUMERATION_LANGUAGE );
		if( this.enumeration.items[0].value != '' ) {
			this.enumeration.items.unshift( {value: '', label: this.resource.enumNotDefined} )
		}
	}
	isValueEmpty(valueIndex) {
		let value = this.field.value[valueIndex].value;
		if( value == null || value == undefined )
			return true;
		return ! value.lang && ! value.value;
	}

	handleEnumLangValueChange = (e) => {
		let value = e.target.value;
		let index = this.state.selectedIndex;
		let newValue = this.field.value[index].value;
		newValue.lang = value;
		this.props.instance.setFieldValue( this.field.value[index], newValue );
	}
	handleStringValueChange = (e) => {
		let value = e.target.value;
		let index = this.state.selectedIndex;
		let newValue = this.field.value[index].value;
		newValue.value = value;
		this.props.instance.setFieldValue( this.field.value[index], newValue );
	}
	async handleInstanceChange(event) {
		super.handleInstanceChange(event);
		if( event.eventType == IET_ADD_LIST_ITEM && event.field == this.field) {
			let currentLanguage = getLanguage();
			let lastItem = this.field.value[ this.field.value.length-1 ];
			let newValue = lastItem.value;
			newValue.lang = currentLanguage;
			this.props.instance.setFieldValue( lastItem, newValue );
		}
	}

	render() {
		if( ! this.field ) {
			return this.createDivUnknownField();			
		}
		
		let readOnly = getDefinedValue( this.props.readOnly, false);
		let langOptionsHtml = this.enumeration.items.map((item, index) => {
			return (<option key={index} value={item.value} title={item.description} >{item.label}</option>);
		});

		let headerHtml = this.createHeaderHtml();
		let tableHtml = null;
		
		if( this.field.value.length == 0 ) {
			if( ! this.props.labelEmptyToHeader ) {
				tableHtml = (
					<div style={{fontStyle: 'italic', color: 'grey'}}>
						{this.resource.labelEmptyTable}
					</div>
				);
			}
		} else {
			let rowsHtml = this.field.value.map((itemField, index) => {
				let trStyle = {backgroundColor: 'lightGray'};
				if( index == this.state.selectedIndex )
					trStyle.backgroundColor = 'lightBlue';
				return (
					<tr key={itemField.fieldId} style={trStyle} onClick={ () => {this.handleRowClick(index)} }>
						<td width={'200px'}>
							<select className={stylesPlain.selectEnum} style={{maxWidth: '190px'}} 
								value={this.state.value[index].value.lang} onChange={this.handleEnumLangValueChange} 
							>
								{langOptionsHtml}
							</select>
						</td>
						<td>
							<input className={stylesPlain.inputFieldString} style={{width: '98%'}} 
								readOnly={readOnly}
								value={this.state.value[index].value.value} onChange={ this.handleStringValueChange}
							/>
						</td>
					</tr>
				)
			});
			let tableStyle = {...getDefinedItem( this.props, ['table', 'styles', 'table'], {})};
			tableStyle.width = getDefinedItem( this.props, ['table','width'], '100%');
			tableHtml = (
				<table border='1' style={tableStyle}>
					<tbody>
						{rowsHtml}
					</tbody>
				</table>
			);
		}
		let divMainStyle = {...getDefinedItem(this.props, ['styles', 'divMain'], {})};
		let headerPosition = getDefinedValue(this.props.headerPosition, HEADER_POSITION_TOP);
		if( headerPosition == HEADER_POSITION_RIGHT ) {
			divMainStyle.display = 'flex';
			divMainStyle.alignItems = 'start';
			let tableFlexGrow = ( this.field.value.length == 0 ) ? '0' : '1';
			return (
				<div style={divMainStyle}>
					<div style={{flexGrow: tableFlexGrow}}>
						{tableHtml}
					</div>
					<div style={{marginLeft: '10px'}}>
						{headerHtml}
					</div>
				</div>
			);
		}
		return (
			<div style={divMainStyle}>
				{headerHtml}
				{tableHtml}
			</div>
		);
	}
}

export default FieldList_MultilangString;
