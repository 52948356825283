import React from 'react';
import stylesPlain from './plain.module.css';

import { getDefinedItem } from '../../util/tools';
import { getDefaultStyles } from '../../util/realm/styles';

class Label extends React.Component {

	fieldModel;

	/*
		instance
		parentField
		path
		styles
			divMain
	*/
	constructor(props) {
		super(props);
		if( props.instance ) {
			let field = null
			if( props.parentField ) {
				field = props.instance.getField(props.path, props.parentField );
			} else {
				field = props.instance.getField(props.path);
			}
			if( field ) {
				this.fieldModel = field.model;
			}
		}
	}

	render() {
		
		let title;
		let tooltip = null;
		if( this.fieldModel ) {
			title = this.fieldModel.title;
			tooltip = this.fieldModel.description;
		} else {
			title = this.props.path; 
		}

		let divMainStyle = Object.assign({}, getDefaultStyles().label, getDefinedItem(this.props, ['styles', 'divMain']));

		if( tooltip )
			return (
				<div className={stylesPlain.divLabelWithTooltip} style={divMainStyle}>
					{title} 
					<span className={stylesPlain.tooltiptext}>{tooltip}</span>
				</div>
			);
		else {
			return (
				<div style={divMainStyle}>
					{title} 
				</div>
			);
		}
	}

}

export default Label;