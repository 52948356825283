import React from 'react';

import FieldString from '../../../components/plain/FieldString';
import FieldEnum from '../../../components/plain/FieldEnum';

class TabBrArrange extends React.Component {
	
	constructor(props) {
		super(props);
	}

	render() {
		let plain = this.props.plain;
		let instance = this.props.instance;
		let parentField = this.props.parentField;
		if( ! parentField ) {
			parentField = instance.rootField;
		}
		return (
			<div style={{padding: '20px 20px 20px 20px' }}>
				<div style={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
					<FieldString instance={instance} path={'arrange/cipher'} parentField={parentField} plain={plain} 
						styles={{ input: {flexGrow: '2', marginRight: '20px'}}}
					/>
					<FieldString instance={instance} path={'arrange/authSign'} parentField={parentField} plain={plain} 
						styles={{ input: {flexGrow: '1', marginRight: '20px'}}}
					/>
					<FieldString instance={instance} path={'arrange/formNumber'} parentField={parentField} plain={plain} 
						styles={{ input: {flexGrow: '2'}}}
					/>
				</div>
				<div style={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
					<FieldEnum instance={instance} path={'arrange/docType'} parentField={parentField} plain={plain} 
						styles={{ select: {flexGrow: '1', marginRight: '20px'}}}
					/>
					<FieldEnum instance={instance} path={'arrange/litType'} parentField={parentField} plain={plain} 
						styles={{ select: {flexGrow: '1'}}}
					/>
				</div>
				<div style={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
					<FieldEnum instance={instance} path={'arrange/docKind'} parentField={parentField} plain={plain} 
						styles={{ select: {flexGrow: '1', marginRight: '20px'}}}
					/>
					<FieldEnum instance={instance} path={'arrange/docAnalyticLevel'} parentField={parentField} plain={plain} 
						styles={{ select: {flexGrow: '1'}}}
					/>
				</div>
				<div style={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
					<FieldString instance={instance} path={'arrange/knowledge'} parentField={parentField} plain={plain} 
						styles={{ input: {flexGrow: '1'}}}
					/>
				</div>
			</div>
		)
	}
}

export default TabBrArrange; 