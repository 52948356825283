import React from 'react';

import FieldList from './FieldList';
import FieldEnum from './FieldEnum';

import { getDefinedItem, getCurrentResource } from '../../util/tools';
import { IET_SET_VALUE } from '../../util/realm/instance';

class FieldList_Enum extends FieldList {

	resource = null;

	/*
		enumeration
		styles
			divMain
	*/
	constructor(props) {
		super(props);
		this.init();
		this.resource = getCurrentResource().components.plain.FieldList_Enum;
	}
	isValueEmpty(valueIndex) {
		let value = this.field.value[valueIndex].value;
		if( value == null || value == undefined )
			return true;
		return value == '';
	}

	handleInstanceChange = async (event) => {
		if( event.field.parent == this.field) {
			if( event.eventType == IET_SET_VALUE && event.newValue == '' && ! event.field.isProposal) {
				this.deleteItem();
			} else {
				await super.handleInstanceChange(event);
			}
		} else {
			await super.handleInstanceChange(event);
		}
	}
	
	render() {
		if( ! this.field ) {
			return this.createDivUnknownField();			
		}
		
		let headerHtml = this.createHeaderHtml();
		let tableHtml = null;
		
		if( this.field.value.length == 0 ) {
			if( ! this.props.labelEmptyToHeader ) {
				tableHtml = (
					<div style={{fontStyle: 'italic', color: 'grey'}}>
						{this.resource.labelEmptyTable}
					</div>
				);
			}
		} else {
			let rowsHtml = this.field.value.map((itemField, index) => {
				let trStyle = {backgroundColor: 'lightGray'};
				if( index == this.state.selectedIndex )
					trStyle.backgroundColor = 'lightBlue';
				let path = '/' + index;
				let styleSelect = {width: '98%'};
				for(let i=0; i < this.field.value.length; i++) {
					if( i != index && this.field.value[i].value == itemField.value ) {
						styleSelect.backgroundColor = 'lightYellow'
						break;
					}
				}
				return (
					<tr key={itemField.fieldId} style={trStyle} onClick={ () => {this.handleRowClick(index)} }>
						<td>
							<FieldEnum instance={this.props.instance} path={path} plain={this.props.plain} parentField={this.field} 
								styleSelect={styleSelect} enumeration={this.props.enumeration} ignoreTitle={true}
							/>
						</td>
					</tr>
				)
			});
			let tableStyle = {...getDefinedItem( this.props, ['table', 'styles', 'table'], {})};
			tableStyle.width = getDefinedItem( this.props, ['table','width'], '100%');
			tableHtml = (
				<table border='1' style={tableStyle}>
					<tbody>
						{rowsHtml}
					</tbody>
				</table>
			);
		}
		
		let divMainStyle = getDefinedItem(this.props, ['styles', 'divMain'], {});
		return (
			<div style={divMainStyle}>
				{headerHtml}
				{tableHtml}
			</div>
		);
	}
}

export default FieldList_Enum;
