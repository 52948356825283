import React, { Component } from 'react';
import Modal from 'react-responsive-modal';

import NavBar from '../../components/navigation/NavBar';
import RateView from '../../components/rateview/RateView';

import { getCurrentResource } from '../../util/tools';
import { canPerformAction } from '../../util/localStorage';
import { ACTION_EBOOK_BANK_IMPORT } from '../../util/consts/actions';
import { FORM_EBOOK_BANK_TABLE } from '../../util/consts/forms';
import { 
	performApiRequestForm, 
	ACTION_EBOOK_BANK_IMPORT_API_URL 
} from '../../util/restapi';

import iconImport from '../../assets/icons/png24x24/get_message.png';

const MODAL_MODE_ACTIVE = 'a';
const MODAL_MODE_WAIT = 'w';
const MODAL_MODE_COMPLETE = 'c';
const MODAL_MODE_ERROR = 'e';

class EBookBankListPage extends Component {
	
	constructor(props) {
		super(props);
		this.refImportModal = React.createRef();
		this.state = {
			isImportModalOpen: false,
		}

		this.resource = getCurrentResource().pages.tableform.EBookBankList;
		var paramCacheId = props.match.params.param;

		this.rvContext = {
			form: FORM_EBOOK_BANK_TABLE,
			data: []
		};
		this.rvOptions = {
			form: FORM_EBOOK_BANK_TABLE,
			formTitle: this.resource.formTitle,
			paramCacheId: paramCacheId,
			transition: null,
			controlPanelTools: this.controlPanelTools(),
		};
	}
	controlPanelTools = () => {
		return (
			<div style={{textAlign: 'left', marginLeft: '20px'}}>
				<ControlPanelButtonImport holder={this} />
			</div>			
		);
	}
	openImportModal = () => {
		this.refImportModal.current.initModal();
		this.setState({ isImportModalOpen: true });	
	}
	closeImportModal = () => {
		this.setState({ isImportModalOpen: false });	
	}

	render() {
		return (
			<React.Fragment>
				<NavBar app={this.props.app} />
				<RateView rvContext={this.rvContext} rvOptions={this.rvOptions} app={this.props.app}  />
				<ImportModal ref={this.refImportModal} page={this} 
					isModalOpen={this.state.isImportModalOpen} 
					close={this.closeImportModal} 
				/>
			</React.Fragment>
		)
	}
	
}

class ImportModal extends React.Component {

	constructor(props) {
		super(props);
		this.resource = props.page.resource;
		this.fileImportRef = React.createRef();
		this.file = null;
		this.resultMessage = '';
		this.resultLog = '';
		this.state = {
			modalMode: MODAL_MODE_ACTIVE,
			fileName: this.resource.textSelectFile
		}
	}
	initModal = () => {
		this.file = null;
		this.resultMessage = '';
		this.resultLog = '';
		this.setState({
			modalMode: MODAL_MODE_ACTIVE,
			fileName: this.resource.textSelectFile
		});
	}
	tryAgain = () => {
		this.setState({	modalMode: MODAL_MODE_ACTIVE});
	}

	handleFileSelect = (e) => {
		var files = e.target.files;
		if( files && files.length > 0 ) {
			this.file = files[0];
			this.setState({fileName: this.file.name})
		}
	}	

	startImport = async () => {
		this.setState({modalMode: MODAL_MODE_WAIT})
		const form = new FormData();
		form.append('format', 'xml');
		form.append('file', this.file);
		var response = await performApiRequestForm(ACTION_EBOOK_BANK_IMPORT_API_URL, form);
		if( response.success ) {
			this.resultMessage = response.message;
			this.resultLog = response.log;
			if( this.resultMessage == '' ) {
				this.resultMessage = this.resource.messageImportComplete;
			} 
		} else {
			this.resultMessage = response.message; 
			this.resultLog = response.log;
		}
		this.setState({modalMode: MODAL_MODE_COMPLETE})
	}

	render() {
		var resource = this.resource;
		if( this.state.modalMode == MODAL_MODE_COMPLETE ) {
			var divAdditionalMessage = null;
			if( this.resultLog ) {
				var divAdditionalMessage = (
					<div style={{marginLeft: 'auto', marginRight: 'auto', marginBottom: '30px', width: '95%'}}>
						<textarea style={{width: '100%'}} rows="8" readOnly={true}>
							{this.resultLog}
						</textarea>
					</div>		
				);
			}
			var modalBodyHtml = (
				<React.Fragment>
					<div style={{color: 'red', textAlign: 'center', padding: '30px', fontSize: '16pt'}} 
						dangerouslySetInnerHTML={{__html: this.resultMessage}}
					/>
					{divAdditionalMessage}
					<div style={localStyles.divButtons}>
						<button type='button' onClick={this.tryAgain} >
							{resource.buttonTryAgain}
						</button>
					</div>
				</React.Fragment>
			);
		} else {
			var buttonImportDisabled = this.state.modalMode == MODAL_MODE_WAIT || this.file == null;
			var divWaiting = this.state.modalMode == MODAL_MODE_WAIT ?
				(<div style={localStyles.divWaiting}>{resource.waiting}</div>) : null;  
			var modalBodyHtml = (
				<div style={{margin: '15px'}}>
					<div>
						{resource.labelFile}: 
						<span style={{marginLeft: '5px', marginRight: '10px'}}>{this.state.fileName}</span> 
						<button onClick={e => this.fileImportRef.current.click()}>...</button>
						<input ref={this.fileImportRef} type={'file'}  style={{display: 'none'}} 
							onChange={e => this.handleFileSelect(e)} 
						/>
					</div>
					{divWaiting}
					<div style={localStyles.divButtons}>
						<button type='button' title={resource.buttonImportTooltip}
							onClick={this.startImport} disabled={buttonImportDisabled}
						>
							{resource.buttonImport}
						</button>
					</div>
				</div>
			);
		}
		return (
			<Modal 
				open={this.props.isModalOpen} onClose={this.props.close} center 
				styles={styleModal} closeOnOverlayClick={false} animationDuration={1} 
			>
				<div>
					<div style={localStyles.divHeader}>
						{this.resource.modalImportTitle}
					</div>
					{modalBodyHtml}
				</div>
			</Modal>
		);
	}
}

function ControlPanelButtonImport(props) {
	
	if( canPerformAction( ACTION_EBOOK_BANK_IMPORT ) ) {
		return (
			<button type='button' style={{marginLeft: '5px', width: '36px', height: '30px'}}
				 onClick={props.holder.openImportModal}
				 title={props.holder.resource.buttonImportTooltip} 
			>
				<img src={iconImport} />
			</button>			
		);
	} else {
		return null;
	}
}

const styleModal = {
	modal: {
		background: '#ffffff',
		padding: '0px',
		borderRadius: '21px',
		maxWidth: '500px',
		width: '100%',
	},
	closeButton: {
		top: '10px',
		right: '10px',
		fill: '#FF0000',
		outline: 'none',
	},
};

const localStyles = {
	divHeader: {
		fontSize: '16pt',
		textAlign: 'center',
		padding: '10px',
		borderRadius: '21px 21px 0 0',
		backgroundColor: '#F0F0F0'
	},
	divParameters: {
		marginTop: '10px',
		padding: '10px'
	},
	divWaiting: {
		textAlign: 'center',
		padding: '7px'
	},
	divButtons: {
		marginTop: '7px',
		padding: '10px',
		marginRight: '15px',
		textAlign: 'right'
	}
};

export default EBookBankListPage;
