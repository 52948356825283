import React from 'react';

import FieldList from './FieldList';
import FieldString from './FieldString';

import { getDefinedItem } from '../../util/tools';

class FieldList_Strings extends FieldList {

	resource = null;

	/*
		styles
			divMain
	*/
	constructor(props) {
		super(props);
		this.init();
	}
	isValueEmpty(valueIndex) {
		let value = this.field.value[valueIndex].value;
		if( value == null || value == undefined )
			return true;
		return value == '';
	}
	
	render() {
		if( ! this.field ) {
			return this.createDivUnknownField();			
		}
		
		let headerHtml = this.createHeaderHtml();
		let tableHtml = null;

		if( this.field.value.length == 0 ) {
			if( ! this.props.labelEmptyToHeader ) {
				tableHtml = (
					<div style={{fontStyle: 'italic', color: 'grey'}}>
						{this.resource.labelEmptyTable}
					</div>
				);
			}
		} else {
			let rowsHtml = this.field.value.map((itemField, index) => {
				let trStyle = {backgroundColor: 'lightGray'};
				if( index == this.state.selectedIndex )
					trStyle.backgroundColor = 'lightBlue';
				let path = '/' + index;
				return (
					<tr key={itemField.fieldId} style={trStyle} onClick={ () => {this.handleRowClick(index)} }>
						<td>
							<FieldString instance={this.props.instance} path={path} 
								plain={this.props.plain} parentField={this.field} ignoreTitle={true} styleInput={{width: '98%'}} />
						</td>
					</tr>
				)
			});

			let tableStyle = {...getDefinedItem( this.props, ['table', 'styles', 'table'], {})};
			tableStyle.width = getDefinedItem( this.props, ['table','width'], '100%');
			tableHtml = (
				<table border='1' style={tableStyle}>
					<tbody>
						{rowsHtml}
					</tbody>
				</table>
			);
		}

		let divMainStyle = getDefinedItem(this.props, ['styles', 'divMain'], {});
		return (
			<div style={divMainStyle}>
				{headerHtml}
				{tableHtml}
			</div>
		);
		
	}
}

export default FieldList_Strings;
