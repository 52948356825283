import React from 'react';
import stylesPlain from '../../components/plain/plain.module.css';

import PlainPage from '../../components/plain/PlainPage';
import Label from '../../components/plain/Label';
import FieldDate from '../../components/plain/FieldDate';
import FieldString from '../../components/plain/FieldString';
import FieldEnum from '../../components/plain/FieldEnum';
import TabbedPane from '../../components/plain/TabbedPane';

import TabBrNames from './components/TabBrNames';
import TabBrAuthors from './components/TabBrAuthors';
import TabBrEdition from './components/TabBrEdition';
import TabBrPhysChar from './components/TabBrPhysChar';
import TabBrContent from './components/TabBrContent';
import TabBrIdentification from './components/TabBrIdentification';
import TabBrParameters from './components/TabBrParameters';
import TabBrDocLinks from './components/TabBrDocLinks';
import TabBrTopicLinks from './components/TabBrTopicLinks';
import TabBrFiles from './components/TabBrFiles';
import TabBrImages from './components/TabBrImages';
import TabBrArrange from './components/TabBrArrange';
import TabBrDerivatives from './components/TabBrDerivatives';
import TabBrHonors from './components/TabBrHonors';

import {getLanguage} from '../../util/localStorage';
import {getDefinedValue} from '../../util/tools';
import {FORM_BOOK_PLAIN} from '../../util/consts/forms';
import { 
	ENUMERATION_CONTRIBUTOR_TYPE, ENUMERATION_LANGUAGE, ENUMERATION_MATERIAL_TYPE, 
	ENUMERATION_SHARE_TYPE, ENUMERATION_TITLE_TYPE, 
	ENUMERATION_DERIVATIVE_TYPE, ENUMERATION_HONOR_TYPE, ENUMERATION_PAGE_TYPE,
	ENUMERATION_PRODUCT_FORM_DETAIL,

} from '../../util/consts/common';
import { IET_ADD_LIST_ITEM } from '../../util/realm/instance';

class BookPlainPage extends PlainPage {

	enumContributorType = null;
	enumLanguage = null;
	enumMaterialType = null;
	enumShareType = null;
	enumTitleType = null;
	enumDerivativeType = null;
	enumHonorType = null;
	enumPageType = null;
	enumProductFormDetail = null;

	constructor(props) {
		super(props);
		this.formPath = FORM_BOOK_PLAIN;
		this.formTitle = 'Книга';
		this.buildInstanceCard = this.buildBookCard;
	}
	async completeInit() {
		this.enumContributorType = this.realm.getEnumeration( ENUMERATION_CONTRIBUTOR_TYPE );
		this.enumLanguage = this.realm.getEnumeration( ENUMERATION_LANGUAGE );
		this.enumMaterialType = this.realm.getEnumeration( ENUMERATION_MATERIAL_TYPE );
		this.enumShareType = this.realm.getEnumeration( ENUMERATION_SHARE_TYPE );
		this.enumTitleType = this.realm.getEnumeration( ENUMERATION_TITLE_TYPE );
		this.enumDerivativeType = this.realm.getEnumeration( ENUMERATION_DERIVATIVE_TYPE );
		this.enumHonorType = this.realm.getEnumeration( ENUMERATION_HONOR_TYPE );
		this.enumPageType = this.realm.getEnumeration( ENUMERATION_PAGE_TYPE );
		this.enumProductFormDetail = this.realm.getEnumeration( ENUMERATION_PRODUCT_FORM_DETAIL );
		
		return true;
	}
	componentDidMount = async () => {
		this.init('Book')
	}
	createInstanceFromBean(bean) {
		let instance = super.createInstanceFromBean(bean);
		let rootField = instance.rootField;
		
		let fieldIdentificationItem = this.createArrayItem(instance, rootField.value.identification);
		if( fieldIdentificationItem != null ) {
			fieldIdentificationItem.value.type.value = 'ISBN'; 
		}
		let fieldLanguageItem = this.createArrayItem(instance, instance.rootField.value.content.value.language);
		if( fieldLanguageItem != null ) {
			fieldLanguageItem.value = 'ukr'; 
		}
		this.createArrayItem(instance, instance.rootField.value.content.value.audience);

		for(let i=rootField.value.topicLink.value.length-1; i >= 0; i--) {
			let topicLink = rootField.value.topicLink.value[i];
			if( topicLink.value.topicLinkValue.value == null && topicLink.value.topicLinkSpecValue.value.length == 0 ) {
				rootField.value.topicLink.value.splice(i,1);
			}
		}
		
		return instance;
	}
	createArrayItem = (instance, arrayField) => {
		if( arrayField.value.length > 0 )
			return null;
		instance.addListItem(arrayField, null);
		var item = arrayField.value[0];
		return item;
	}
	handleInstanceChange(event) {
		super.handleInstanceChange(event);
		let instance = this.state.instance;
		let fieldInstitution = instance.rootField.value.content.value.institution;
		if( event.field === fieldInstitution && event.eventType == IET_ADD_LIST_ITEM ) {
			let fieldName = fieldInstitution.value[ fieldInstitution.value.length-1 ].value.name;
			instance.addListItem(fieldName);
		}
	}

	addContributorName(currentName, contributor, prefix) {
		var currentLanguage = getLanguage();
		var name = null;
		for(let n of contributor.value.names.value) {
			if( n.value.language.value == currentLanguage && n.value.shortName.value ) {
				name = n;
				break;
			}
		}
		if( name == null && contributor.value.names.value.length > 0)
			name = contributor.value.names.value[0];
		if( name != null  && name.value.shortName.value ) {
			var pref = name.value.role.value;
			if( ! pref )
				pref = getDefinedValue(prefix, '');
			if( currentName )
				currentName += ', ' + name.value.shortName.value;
			else
				currentName = pref + ' ' + name.value.shortName.value;
		} 
		return currentName;
	}
	buildBookCard = (bookInstance) => {
		var currentLanguage = getLanguage();
		var card = '';
		
		var titleArea = '';
		var titleAreaCt = ''
		for(let ttl of bookInstance.rootField.value.title.value) {
			if(ttl.value.titleType.value == 'Modern') {
				if( ttl.value.language.value == currentLanguage ) {
					titleArea = ttl.value.mainName.value; 
					if( ttl.value.subName.value ) 
						titleArea += ": " + ttl.value.subName.value;
					break;
				} else if( ttl.value.language.value == this.instance.rootField.value.parameter.value.language.value ) {
					titleAreaCt = ttl.value.mainName.value;
				}
			}
		}
		if( titleArea == '' )
			titleArea = titleAreaCt;
		var materialType = this.instance.rootField.value.edition.value.materialType.value;
		if( materialType ) {
			var materialTypeText = this.realm.getEnumerationItemText( this.enumMaterialType, materialType);
			titleArea += ' [' + materialTypeText + ']';
		}

		var author = '', compiler = '', editor = '', translator = '', illustrator = '';
		for(let con of bookInstance.rootField.value.contributor.value) {
			var contriburorType = con.value.type.value;
			var contriburorName = this.realm.getEnumerationItemText( this.enumContributorType, contriburorType);
			if( contriburorType == 'A01' ) { // AUTHOR
				author = this.addContributorName(author, con, '');
			} else if( contriburorType == 'C01' ) { // COMPILER
				compiler = this.addContributorName(compiler, con, contriburorName);
			} else if( contriburorType == 'B01' ) { // EDITOR
				editor = this.addContributorName(editor, con, contriburorName);
			} else if( contriburorType == 'B06' ) { // TRANSLATOR
				translator = this.addContributorName(translator, con, contriburorName);
			} else if( contriburorType == 'A12' ) { // ILLUSTRATOR
				illustrator = this.addContributorName(illustrator, con, contriburorName);
			}
		}
		var respArea = author
		if( compiler )
			respArea += (respArea) ? " ; " + compiler : compiler;
		if( editor )
			respArea += (respArea)? " ; " + editor : editor;
		if( translator )
			respArea += (respArea)? " ; " + translator : translator;
		if( illustrator )
			respArea += (respArea)? " ; " + illustrator : illustrator;
		
		card += titleArea;
		if( respArea )
			card += " / " + respArea;
		return card;
	}
	
	createTab = (tabId) => {
		if( tabId == 'parameters' )
			return <TabBrParameters plain={this} instance={this.state.instance}/>;
		if( tabId == 'identidication' )
			return <TabBrIdentification plain={this} instance={this.state.instance} />;
		if( tabId == 'names' )
			return <TabBrNames plain={this} instance={this.state.instance} />;
		if( tabId == 'authors' )
			return <TabBrAuthors plain={this} instance={this.state.instance} />;
		if( tabId == 'edition' )
			return <TabBrEdition plain={this} instance={this.state.instance} />;
		if( tabId == 'physChar' )
			return <TabBrPhysChar plain={this} instance={this.state.instance} />;
		if( tabId == 'content' )
			return <TabBrContent plain={this} instance={this.state.instance} />;
		if( tabId == 'docLinks' )
			return <TabBrDocLinks plain={this} instance={this.state.instance} />;
		if( tabId == 'topicLinks' )
			return <TabBrTopicLinks plain={this} instance={this.state.instance} />;
		if( tabId == 'files' )
			return <TabBrFiles plain={this} instance={this.state.instance} />;
		if( tabId == 'images' )
			return <TabBrImages plain={this} instance={this.state.instance} />;
		if( tabId == 'arrange' )
			return <TabBrArrange plain={this} instance={this.state.instance} />;
		if( tabId == 'derivatives' )
			return <TabBrDerivatives plain={this} instance={this.state.instance} />;
		if( tabId == 'honors' )
			return <TabBrHonors plain={this} instance={this.state.instance} />;
		return null;
	}
	
	renderBody() {
		let tabs = [
			{
				rowId: 'first',
				tabs: [
					{ tabId: 'names', tabTitle: 'Назви', },
					{ tabId: 'authors', tabTitle: 'Автори', },
					{ tabId: 'content', tabTitle: 'Зміст', },
					{ tabId: 'edition', tabTitle: 'Видання', },
					{ tabId: 'physChar', tabTitle: 'Фізичні характеристики', },
					{ tabId: 'identidication', tabTitle: 'Ідентифікація', },
					{ tabId: 'arrange', tabTitle: 'Упорядкування', },
				]
			},
			{
				rowId: 'second',
				tabs: [
					{ tabId: 'topicLinks', tabTitle: 'Теми класифікатора', },
					{ tabId: 'images', tabTitle: 'Зображення', },
					{ tabId: 'docLinks', tabTitle: 'Зв\'язки з документами', },
					{ tabId: 'files', tabTitle: 'Електронні копії', },
					{ tabId: 'derivatives', tabTitle: 'Екранізації', },
					{ tabId: 'honors', tabTitle: 'Відзнаки і нагороди', },
					{ tabId: 'parameters', tabTitle: 'Параметри', },
				]
			},
		];
		
		let instance = this.state.instance;
		
		return (
			<div className={stylesPlain.divPlainMain}>
				{this.renderHeader()}
				
				{/* Body */}
				<div className={stylesPlain.divPlainBody} >
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
						<FieldString instance={instance} path={'/publisherUri'} plain={this}
							styles={{ divMain: {flexGrow: '1'} }}
						/>
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
						<Label title={'ISBN'} styles={{divMain: {marginRight: '10px'}}} />
						<FieldString instance={this.state.instance} path={'/identification/0/number'} plain={this} 
							ignoreTitle={true} styles={{ input: {flexGrow: '1', marginRight: '20px'} }}
						/>
						<FieldEnum instance={this.state.instance} path={'/content/language/0'} plain={this} 
							styles={{ select: {flexGrow: '1', marginRight: '20px'}}} 
						/>
						<FieldDate instance={this.state.instance} path={'/bcudate'} plain={this} 
							styles={{ input: {flexGrow: '1'}}} 
						/>
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
						<FieldEnum instance={this.state.instance} path={'/edition/productForm'} plain={this} 
							styles={{ select: {flexGrow: '1', marginRight: '20px'}}} 
						/>
						<FieldEnum instance={this.state.instance} path={'/content/audience/0'} plain={this} 
							styles={{ select: {flexGrow: '1', marginRight: '20px'}}} 
						/>
						<FieldEnum instance={this.state.instance} path={'/arrange/litType'} plain={this} 
							styles={{ select: {flexGrow: '1'}}} 
						/>
					</div>
					<TabbedPane tabs={tabs} createTab={this.createTab} 
						styles={{ divMain: {border: '1px solid gray', marginTop: '10px'} }} 
					/>
				</div>
			</div>
		)
	}
}

export default BookPlainPage;
