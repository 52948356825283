import React from 'react';
import stylesPlain from './plain.module.css';

import { getDefinedValue, getDefinedItem } from '../../util/tools';
import { getDefaultStyles } from '../../util/realm/styles';

class Label extends React.Component {

	/*
		props
			title
			tooltip
			style   !!! Obsolete
			styles
				divMain
	*/

	render() {
		let title = this.props.title;
		let tooltip = this.props.tooltip;

		let divMainStyle = Object.assign({}, 
				getDefaultStyles().label,
				getDefinedValue(this.props.style, {}), 
				getDefinedItem(this.props, ['styles', 'divMain']));

		if( tooltip )
			return (
				<div className={stylesPlain.divLabelWithTooltip} style={divMainStyle}>
					{title} 
					<span className={stylesPlain.tooltiptext}>{tooltip}</span>
				</div>
			);
		else {
			return (
				<div style={divMainStyle}>
					{title} 
				</div>
			);
		}
	}

}

export default Label;