import React, { Component } from 'react';

import NavBar from '../../components/navigation/NavBar';

import { getCurrentResource, formatDateTime } from '../../util/tools';
import { performApiRequest, SYSINFO_GET_API_URL, } from '../../util/restapi';
import { traceLocalStorage } from '../../util/localStorage';

const FORM_STATE_READY = 'ready';
const FORM_STATE_WAITING = 'wait';

class SystemInfoPage extends Component {
	
	constructor(props) {
		super(props);
		this.resource = getCurrentResource().pages.dbmanager.DbManagerPage;
		this.state = {
			formState: FORM_STATE_WAITING,
			sysInfo: null
		};
		
	}
	componentDidMount = async () => {
		this.setState({formState: FORM_STATE_WAITING})
		const request = {
			cacheStatistic: true
		};
		var response = await performApiRequest( SYSINFO_GET_API_URL, request );
		if( ! response.success ) {
			this.props.app.showResponse( response );
		} else {
			this.setState({sysInfo: response.data})
		}
		this.setState({formState: FORM_STATE_READY})
	}
		
	render() {
		if( this.state.formState == FORM_STATE_WAITING ) {
			return (
				<div>
					<NavBar app={this.props.app} />
					<div style={localStyles.divMain}>
						Waiting
					</div>
				</div>
			)
		}
		let itemQty = this.state.sysInfo.cacheStatistic.itemCount;
		let statistic = this.state.sysInfo.cacheStatistic; 
		let time = statistic.oldestActiveTime;
		let date = new Date(time);
		let oldestItemTime = '';
		if( time > 0 )
			oldestItemTime = formatDateTime( date );
		let lastClearTime = formatDateTime( new Date( statistic.lastClearTime ) )
		return (
			<div>
				<NavBar app={this.props.app} />
				<div style={localStyles.divMain}>
					<div style={{marginBottom: '15px'}}>
						System info
					</div>
					<div style={{padding: '15px', textAlign: 'left', border: '1px solid blue', backgroundColor: 'rgb(230,230,230)'}}>
						<div style={{marginBottom: '15px', fontWeight: 'bold', color: 'blue'}}>
							CACHE
						</div>
						<div style={{marginBottom: '15px'}}>
							Item quantity: {itemQty}
						</div>
						<div style={{marginBottom: '15px'}}>
							Oldest item time: {oldestItemTime}
						</div>
						<div style={{marginBottom: '15px'}}>
							Last clear time: {lastClearTime}
						</div>
					</div>
					<div style={{padding: '15px 0 15px 0', textAlign: 'left'}}>
						<button type='button' onClick={traceLocalStorage}>Trace local storage</button>
					</div>
				</div>
			</div>
		)
	}
	
}

const localStyles = {
	divMain: {
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: '30px',
		marginBottom: '30px',
		padding: '20px',
		width: '1000px',
		border: '1px solid black',
		backgroundColor: 'rgb(240,240,240)'
	}
}
export default SystemInfoPage;
