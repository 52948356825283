import React from 'react';
import styles from './FullDescription.module.css';
import config from '../../config/config';
import ButtonIconText from '../button/ButtonIconText';
import SelectInstance from '../../modal/selectinstance/SelectInstance';
import Label from './Label';
import PopupMenu from '../popupmenu/PopupMenu';
import { initFieldStyles, findFieldByPath } from './util';
import { getCurrentResource, getInstanceFieldValue, setInstanceFieldValue } from '../../util/tools';
import { performApiRequest, MASTER_LOAD_REF_API_URL } from '../../util/restapi';

import selectIcon from '../../assets/icons/png24x24/Search.png';
import clearIcon from '../../assets/icons/png24x24/edit-clear.png';

class FieldReference extends React.Component {

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.fulldescription.FieldReference;
		this.selectInstanceRvContext = {
			rateView: null,
			form: null,
			formTitle: null, 
			data: null
		};
		if( this.props.field.model.listObjectRefNode.length > 0 ) {
			this.selectInstanceRvContext.form = this.props.field.model.listObjectRefNode[0].selectInstanceFormId;
			this.selectInstanceRvContext.formTitle = this.props.field.model.listObjectRefNode[0].selectInstanceFormTitle;
		}
		if(props.plain.model ) {
			var fieldOverrideModel = findFieldByPath( props.plain.model.fields, props.field.path );
			if( fieldOverrideModel && fieldOverrideModel.selectInstanceRvContext) {
				for(let key of Object.keys( fieldOverrideModel.selectInstanceRvContext) ) {
					this.selectInstanceRvContext[key] = fieldOverrideModel.selectInstanceRvContext[key];
				}
			}
		}
		var value = getInstanceFieldValue(props.plain.instance, props.field.path);
		this.state = {
			value: value,
			serviceValue: '',
			isSelectInstanceModalOpen: false
		}
	}
	
	handleServiceValueChange = e => {
		this.setState({ serviceValue: e.target.value });
	};
	
	selectInstance = (refNode) => {
		if( this.props.field.model.actionSelectInstance ) {
			this.props.plain.page[ this.props.field.model.actionSelectInstance ]( this );
		} else {
			if( refNode ) {
				this.selectInstanceRvContext.form = refNode.selectInstanceFormId;
				this.selectInstanceRvContext.formTitle = refNode.selectInstanceFormTitle;
			}
			this.setState({ isSelectInstanceModalOpen: true });
		}
	};
	onInstanceSelected = async (ids) => {
		if( ids.length == 0 )
			return;
		var request = {
			id: ids[0]
		}
		var response = await performApiRequest(MASTER_LOAD_REF_API_URL, request);
		if( ! response.success ) {
			this.props.plain.app.showResponse( response );
			return;
		}
		var objectRef = response.data;
		var instance = this.props.plain.instance;
		setInstanceFieldValue(instance, this.props.field.path, objectRef);
		this.setState({ value: objectRef });
		this.closeSelectInstance();
	}
	closeSelectInstance = () => {
		this.setState({ isSelectInstanceModalOpen: false });
	}
	clearValue = () => {
		var instance = this.props.plain.instance;
		setInstanceFieldValue(instance, this.props.field.path, null);
		this.setState({ value: null });
	}
	
	render() {
		if( this.props.listItem ) {
			var listItemTools = this.props.listItem.tools;
		} else {
			var listItemTools = (null);
		}

		var path = this.props.field.path;
		var id = path[ path.length-1 ];
		var value = this.state.value; 
		if( value != null && value.id != null ) {
			var objectRefNode = null;
			for(let item of this.props.field.model.listObjectRefNode) {
				if( item.classId == '*' || item.classId == value.className ) {
					objectRefNode = item;
					break;
				}
			}
			if( objectRefNode == null ) {
				var valueBody = (
					<span>{value.card}</span>
				);
			} else {
				var url = `${config.PATH}/` + objectRefNode.plainFormId + '/' + value.id;
				var valueBody = (
					<a href={url}>{value.card}</a>
				);
			}
		} else {
			var valueBody = this.resource.messageNotDefined;
			if( this.props.field.model.ignoreLabel ) {
				valueBody = this.props.field.model.title + ': ' + valueBody;
			}
		}
	
		var styleFieldScalar = {};
		var styleLabel = {};
		initFieldStyles( this.props.field, styleFieldScalar, styleLabel );
	
		if( ! this.props.field.model.readOnly ) {
			if( this.props.field.model.showServiceField )
				var inputService = (
					<React.Fragment>
						&nbsp;
						<input type='text' size='20' value={this.state.serviceValue} onChange={ this.handleServiceValueChange} />
						&nbsp;
					</React.Fragment>
				)
			else 
				var inputService = (null);
				/* 
						<ButtonIconText 
							useIcon={this.useIcons} 
							disabled={ ! this.selectInstanceRvContext.form }
							onClick={this.selectInstance}
							styles={styles}
							tooltip={this.resource.buttonSelectTooltip}
							text={this.resource.buttonSelect}
							icon={selectIcon}
						/>
				*/
			var editTools = (
				<React.Fragment>
					<div className={styles.divFieldRefTools}>
						{inputService}
						<ButtonSelectInstance holder={this} />
						<ButtonIconText 
							useIcon={this.useIcons}
							style={{marginLeft: '5px'}} 
							onClick={this.clearValue}
							styles={styles}
							tooltip={this.resource.buttonClearTooltip}
							text={this.resource.buttonClear}
							icon={clearIcon}
						/>
						{listItemTools}
						<SelectInstance 
							isModalOpen={this.state.isSelectInstanceModalOpen} 
							closeModal={this.closeSelectInstance}
							title={this.selectInstanceRvContext.formTitle}
							onInstanceSelected={this.onInstanceSelected}
							rvContext = {this.selectInstanceRvContext}
						/>
					</div>
				</React.Fragment>
			);
		} else {
			var editTools = (null);
		}
		return (
			<React.Fragment>
				<div className={styles.divFieldScalar} style={styleFieldScalar}>
					<Label fieldModel={this.props.field.model} listItem={this.props.listItem} style={styleLabel} />
					<div className={styles.divFieldScalarValue}>
						{valueBody}
					</div>
					{editTools}
				</div>
			</React.Fragment>
		);
	}	
}

function ButtonSelectInstance(props) {
	var holder = props.holder;
	var listObjectRefNode = holder.props.field.model.listObjectRefNode; 
	if( ! listObjectRefNode || listObjectRefNode.length == 0 ) {
		return null;
	} else if( listObjectRefNode.length == 1 ) {
		return (
			<ButtonIconText 
				useIcon={holder.useIcons} 
				disabled={ ! holder.selectInstanceRvContext.form }
				onClick={ () => {holder.selectInstance(null)} }
				styles={styles}
				tooltip={holder.resource.buttonSelectTooltip}
				text={holder.resource.buttonSelect}
				icon={selectIcon}
			/>
		);
	} else {
		var style = {};
		var button = {
			text: holder.resource.buttonSelect, 
			tooltip: holder.resource.buttonSelectTooltip, 
			icon: selectIcon
		};
		var menu = { items: [] };
		for(let refNode of listObjectRefNode) {
			menu.items.push( {
				title: refNode.selectInstanceFormTitle,
				method: () => { holder.selectInstance(refNode) }
			} )
		}
		return (
			<PopupMenu holder={holder} style={style} button={button} menu={menu} />
		);
	}
}

export default FieldReference;
