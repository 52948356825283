import React from 'react';
import styles from './FullDescription.module.css';
import Label from './Label';
import { initFieldStyles } from './util';
import { 
	getCurrentResource, getInstanceFieldValue, setInstanceFieldValue, matchPath,
	validInteger, validDecimal 
} from '../../util/tools';

import { 
	STRING_FORMAT_INTEGER,
	STRING_FORMAT_DECIMAL,
	STRING_FORMAT_FORBIDDEN_VALUES,
	STRING_FORMAT_ISBN
} from '../../util/consts/common';


class FieldString extends React.Component {

	constructor(props) {
		super(props);
		var fieldComponent = {
			field: this,
			path: props.field.path
		}
		props.plain.listFieldComponent.push(fieldComponent);
		this.resource = getCurrentResource().components.fulldescription.FieldString;
		this.state = {
			dirty: false
		}
	}
	
	handleValueChange = e => {
		const { name, value } = e.target;
		var instance = this.props.plain.instance;
		setInstanceFieldValue(instance, this.props.field.path, value);
		this.setState({ dirty: true });
	};
	
	isValueValid = (value) => {
		if( value == undefined || value == null || value == '' )
			return true;
		var fieldModel = this.props.field.model;
		if( fieldModel.stringFormat == STRING_FORMAT_INTEGER ) {
			return validInteger( value );
		}
		if( fieldModel.stringFormat == STRING_FORMAT_DECIMAL ) {
			return validDecimal( value );
		}
		if( fieldModel.stringFormat == STRING_FORMAT_FORBIDDEN_VALUES ) {
			for(let fv of fieldModel.listForbiddenValues) {
				if( fv == value )
					return false;
			}
			return true;
		}
		if( fieldModel.stringFormat == STRING_FORMAT_ISBN ) {
			return true;
		}
		
		return true;
	}
	
	goToUrl = () => {
		var value = getInstanceFieldValue(this.props.plain.instance, this.props.field.path);
		if( value ) {
			if( ! value.startsWith("http") )
				value = "http://" + value;
			window.open( value );
		}
	}
	
	render() {
		if( this.props.field.model.url ) {
			return <URL holder={this} />
		} else if( this.props.field.model.multiline ) {
			return <MultilineText holder={this} />
		} else {
			return <InputText holder={this} />
		}
	}	
}

function MultilineText(props) {
	var path = props.holder.props.field.path;
	var id = path[ path.length-1 ];
	var value = getInstanceFieldValue(props.holder.props.plain.instance, path);
	if( value == null )
		value = '';
	
	var styleFieldScalar = { alignItems: 'start' };
	var styleLabel = {display: 'flex', alignContent: 'flex-start'};
	initFieldStyles( props.holder.props.field, styleFieldScalar, styleLabel );
	
	var model = props.holder.props.field.model; 
	if( model.readOnly ) {
		return (
			<div className={styles.divFieldScalar} style={styleFieldScalar}>
				<Label fieldModel={props.holder.props.field.model} style={styleLabel} />
				<div className={styles.divFieldScalarValue}>
					{value}
				</div>
			</div>
		);
	} else {
		return (
			<div className={styles.divFieldScalar} style={styleFieldScalar}>
				<Label fieldModel={props.holder.props.field.model} style={styleLabel} />
				<div className={styles.divFieldScalarValue}>
					<textarea 
						className={styles.textArea} rows={model.mlRows} cols={model.mlCols} 
						onChange={props.holder.handleValueChange}>{value}
					</textarea>
				</div>
			</div>
		);
	}
}

function URL(props) {
	var listItem = props.holder.props.listItem;
	var fieldModel = props.holder.props.field.model;
	var path = props.holder.props.field.path;
	var id = path[ path.length-1 ];
	var value = getInstanceFieldValue(props.holder.props.plain.instance, path);
	if( value == null )
		value = '';

	if( listItem ) {
		var listItemTools = listItem.tools;
	} else {
		var listItemTools = (null);
	}
	
	var styleFieldScalar = {};
	var styleLabel = {};
	initFieldStyles( props.holder.props.field, styleFieldScalar, styleLabel );
	
	if( props.holder.props.field.model.readOnly ) {
		return (
			<div className={styles.divFieldScalar} style={styleFieldScalar}>
				<Label fieldModel={props.holder.props.field.model} style={styleLabel} />
				<div className={styles.divFieldScalarValue}>
					<a href={value}>{value}</a>
				</div>
			</div>
		);
	} else {
		var maxLength = 524288; // Default html standard value
		if( fieldModel.maxLength )
			maxLength = fieldModel.maxLength; 
		return (
			<div className={styles.divFieldScalar} style={styleFieldScalar}>
				<Label fieldModel={props.holder.props.field.model} style={styleLabel} />
				<div className={styles.divFieldScalarValueWithButton} style={{display: 'flex'}}>
					<input className={styles.inputFieldScalarValue} 
						style={{flexGrow: 100}}
						type="text"
						id={id}
						name={id}
						value={value}
						maxLength={maxLength}
						size='1'
						onChange={ props.holder.handleValueChange}
					/>
					<div className={styles.divFieldButton}>
						<button onClick={props.holder.goToUrl} title={props.holder.resource.buttonGoTooltip}>{props.holder.resource.buttonGo}</button>
					</div>
					{listItemTools}
				</div>
			</div>
		);
	}
}

function InputText(props) {
	var listItem = props.holder.props.listItem;
	var fieldModel = props.holder.props.field.model;
	var path = props.holder.props.field.path;
	var id = path[ path.length-1 ];
	var value = getInstanceFieldValue(props.holder.props.plain.instance, path);
	if( value == null )
		value = '';
	
	var styleFieldScalar = {display: 'flex'};
	var styleLabel = {};
	initFieldStyles( props.holder.props.field, styleFieldScalar, styleLabel );
	
	if( props.holder.props.plain.actions ) {
		var actionButtons = props.holder.props.plain.actions.map((action, index) => {
			if( matchPath(action.path, props.holder.props.field.path) ) {
				return ( <ActionButton key={index} action={action} holder={props.holder} /> )
			}
		});
	} else {
		var actionButtons = (null);
	}
	
	if( fieldModel.readOnly ) {
		return (
			<div className={styles.divFieldScalar} style={styleFieldScalar}>
				<Label listItem={listItem} fieldModel={fieldModel} style={styleLabel} />
				<div className={styles.divFieldScalarValue} style={{display: 'flex'}}>
					<input className={styles.inputFieldScalarValue} style={{flexGrow: 100}}
						type='text' value={value} size='1' readOnly={true}
					/>
				</div>
			</div>
		);
	} else {
		var autoComplete = fieldModel.autoComplete;
		if( ! autoComplete )
			autoComplete = 'on';
		if( fieldModel.password ) {
			var inputType = 'password';
		} else {
			var inputType = 'text'; 
		}
		if( listItem ) {
			var listItemTools = listItem.tools;
		} else {
			var listItemTools = (null);
		}
		var maxLength = 524288; // Default html standard value
		if( fieldModel.maxLength )
			maxLength = fieldModel.maxLength;
		var bgColor = props.holder.isValueValid(value) ? 'White' : 'LightYellow'; 
		return (
			<div className={styles.divFieldScalar} style={styleFieldScalar}>
				<Label listItem={listItem} fieldModel={fieldModel} style={styleLabel} />
				<div className={styles.divFieldScalarValue} style={{display: 'flex'}}>
					<input className={styles.inputFieldScalarValue}
						style={{flexGrow: 100, backgroundColor: bgColor}}
						type={inputType}
						id={id}
						name={id}
						value={value}
						maxLength={maxLength}
						autoComplete={autoComplete}
						size='1'
						onChange={ props.holder.handleValueChange}
					/>
					{actionButtons}
					{listItemTools}
				</div>
			</div>
		);
	}
}

function ActionButton(props) {
	if( matchPath(props.action.path, props.holder.props.field.path) ) {
		return (
			<React.Fragment>
				&nbsp;<button type='button' onClick={props.action.perform}>{props.action.title}</button>
			</React.Fragment>
		)
	} else {
		return (null);
	}
}

export default FieldString;
