import React from 'react';

import FieldString from '../../../components/plain/FieldString';
import FieldEnum from '../../../components/plain/FieldEnum';
import FieldImage from '../../../components/plain/FieldImage';
import FieldList_CardsPanel from '../../../components/plain/FieldList_CardsPanel';
import DivFileChar from './DivFileChar';

class TabBrImages extends React.Component {

	constructor(props) {
		super(props);
		this.state = {dirtyCounter: 0 }
	}		
	componentDidUpdate(prevProps, prevState) {
		if ( prevProps.instance !== this.props.instance ) {
			this.setState({ dirtyCounter: this.state.dirtyCounter+1 });
		}
	}
	evaluateDoInit(prevProps, prevState) {
		let doInit = false;
		doInit = doInit || prevProps.instance !== this.props.instance;
		doInit = doInit || prevProps.parentField !== this.props.parentField;
		doInit = doInit || prevProps.path !== this.props.path;
		return doInit;
	}
		
	isImageCardBlockDirty = (event) => {
		let instance = this.props.instance;
		let fieldImages = instance.getField('/imageLink');
		let f = event.field;
		
		let dirty = false;
		dirty = dirty || f.model.fieldId == 'resourceType';
		dirty = dirty || f.model.fieldId == 'resourceName';
		dirty = dirty || f.model.fieldId == 'resourceURI';
		dirty = dirty || f.model.fieldId == 'resourceFile';
		dirty = dirty && f.parent && f.parent.parent == fieldImages;
		return dirty;
	}
	imageCardHtmlBuilder = (fieldImage, index) => {
		let card = this.imageCardBuilder(fieldImage, index);
		let image = fieldImage.value.resourceFile.value;
		let imageHtml = null;
		if( image ) {
			if( image.imageBinary )
				imageHtml = ( <img src={image.imageBinary} style={{width: '100%'}}/> );
			else if( image.fileURL )
				imageHtml = ( <img src={image.fileURL} style={{width: '100%'}}/> );
		} 
		if( imageHtml == null && fieldImage.value.resourceURI.value ) {
			imageHtml = ( <img src={fieldImage.value.resourceURI.value} style={{width: '100%'}}/> );
		}
		if( imageHtml == null ) {
			imageHtml = (<div style={{width: '100%', height: '50px', border: '1px solid black', backgroundColor: 'darkGray'}}/>)
		}
		return (
			<div style={{display: 'flex'}}>
				<div style={{width: '100px', marginRight: '10px'}}>
					{imageHtml}
				</div>
				<div>
					{card}
				</div>
			</div>
		);
	}
	imageCardBuilder = (fieldImage, index) => {
		let plain = this.props.plain;
		let card = '';
		
		let type = fieldImage.value.resourceType.value;
		var typeText = plain.realm.getEnumerationItemText( plain.enumPageType, type);
		if( typeText )
			card += '[' + typeText + ']; ';
		
		if( fieldImage.value.resourceName.value )
			card += fieldImage.value.resourceName.value;
			
		if( card.length > 40 )
			card = card.substring(0, 37) + '...'
		return card;
	}
	
	render() {
		let plain = this.props.plain;
		let instance = this.props.instance;
		let parentField = this.props.parentField;
		if( ! parentField ) {
			parentField = instance.rootField;
		}
		return (
			<div style={{padding: '10px' }}>
				<FieldList_CardsPanel path={'imageLink'} instance={instance} parentField={parentField} plain={plain}
					labelEmptyToHeader={true} itemComponent={ImagePanel}  ignoreTitle={true}
					isCardBlockDirty={this.isImageCardBlockDirty}
					itemCardBuilder={this.imageCardBuilder} itemCardHtmlBuilder={this.imageCardHtmlBuilder}
					styles={{
						divCardsBlock: {
							marginRight: '10px',
							backgroundColor: 'rgb(240,240,240)',
							minWidth: '500px'
						},
						divCard: {
							marginTop: '3px',
							padding: '7px',
							cursor: 'pointer',
							backgroundColor: 'lightGray',
							minHeight: '18px'
						}
					}}
				/>
			</div>
		);
	}
}

class ImagePanel extends React.Component {

	/*
		plain
		instance
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
	}
	
	render() {
		let fieldGroup = this.props.fieldGroup;
		let plain = this.props.plain;
		let instance = this.props.instance;
		return (
			<div style={{flexGrow: '1', backgroundColor: 'lightGray'}}>
				<div style={{margin: '10px', padding: '10px', border: '1px solid gray'}}>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldEnum instance={instance} path={'resourceType'} parentField={fieldGroup} plain={plain}
							enumeration={plain.enumPageType} styleSelect={{flexGrow: '1'}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'resourceName'} parentField={fieldGroup} plain={plain}
							styleInput={{flexGrow: '1'}}
						 />
					</div>
					<DivFileChar instance={instance} fieldGroup={fieldGroup} plain={plain}
						styles={{ divMain: {marginBottom: '15px'} }} 
					/>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldImage instance={instance} path={'resourceFile'} parentField={fieldGroup} plain={plain}
							styleDiv={{flexGrow: '1'}}
						 />
					</div>
				</div>
			</div>
		)
	}
}

export default TabBrImages;
