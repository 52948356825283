import React from 'react';
import stylesPlain from './plain.module.css';

import Field from './Field';
import SelectInstance from '../../modal/selectinstance/SelectInstance';

import { getCurrentResource, getDefinedValue } from '../../util/tools';
import { IET_SET_VALUE } from '../../util/realm/instance';
import { performApiRequest, MASTER_LOAD_REF_API_URL } from '../../util/restapi';

import iconSelect from '../../assets/icons/png24x24/Search.png';
import iconClear from '../../assets/icons/png24x24/edit-clear.png';

class FieldReference extends Field {

	selectInstanceRvContext = {
		rateView: null,
		form: null,
		formTitle: null, 
		data: null
	};

	/*
		refNodes
		styles
			label
			divMain
			divEditTools
	*/
	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.plain.FieldReference;
		if( this.props.refNodes.length > 0 ) {
			this.selectInstanceRvContext.form = this.props.refNodes[0].selectInstanceFormId;
			this.selectInstanceRvContext.formTitle = this.props.refNodes[0].selectInstanceFormTitle;
		}
		this.state.value = this.field ? this.field.value : null;
		this.state.serviceValue = '';
		this.state.isSelectInstanceModalOpen = false;
	}
	
	handleInstanceChange = async (event) => {
		if( event.eventType == IET_SET_VALUE && event.field == this.field) {
			this.setState({ value: event.newValue });
		}
	}
	handleServiceValueChange = e => {
		this.setState({ serviceValue: e.target.value });
	};
	
	selectInstance = (refNode) => {
		if( refNode ) {
			this.selectInstanceRvContext.form = refNode.selectInstanceFormId;
			this.selectInstanceRvContext.formTitle = refNode.selectInstanceFormTitle;
		}
		this.setState({ isSelectInstanceModalOpen: true });
	};
	onInstanceSelected = async (ids) => {
		if( ids.length == 0 )
			return;
		let request = {
			id: ids[0]
		}
		let response = await performApiRequest(MASTER_LOAD_REF_API_URL, request);
		if( ! response.success ) {
			this.props.plain.app.showResponse( response );
			return;
		}
		this.closeSelectInstance();
		let objectRef = response.data;
		this.props.instance.setFieldValue( this.field, objectRef );
	}
	closeSelectInstance = () => {
		this.setState({ isSelectInstanceModalOpen: false });
	}
	clearValue = () => {
		this.props.instance.setFieldValue( this.field, null );
	}
	
	render() {
		let valueHtml = null;
		let editToolsHtml = null;
		let inputServiceHtml = null;
		let value = this.state.value; 
		if( value != null && value.id != null ) {
			let objectRefNode = null;
			for(let item of this.props.refNodes) {
				if( item.classId == '*' || item.classId == value.className ) {
					objectRefNode = item;
					break;
				}
			}
			if( objectRefNode == null ) {
				valueHtml = (<span>{value.card}</span>);
			} else {
				let url = objectRefNode.plainFormPath + '/' + value.id;
				valueHtml = (<a href={url}>{value.card}</a>);
			}
		} else {
			valueHtml = <span style={{fontWeight: 'bold', fontStyle: 'italic'}}>{this.resource.messageNotDefined}</span>;
		}
		
		if( ! this.props.readOnly ) {
			if( this.props.inputService ) {
				// TODO nputServiceHtml = ...
			}
			let divEditToolsStyle = getDefinedValue({...this.props.styles.divEditTools}, {} );
			editToolsHtml = (
				<div style={divEditToolsStyle}>
					{inputServiceHtml}
					<ButtonSelectInstance holder={this} />
					<button type='button' className={stylesPlain.buttonIcon} style={{marginLeft: '5px'}}
						tooltip={this.resource.buttonClearTooltip} onClick={this.clearValue}
					>
						<img src={iconClear} />
					</button>
					<SelectInstance 
						isModalOpen={this.state.isSelectInstanceModalOpen} 
						closeModal={this.closeSelectInstance}
						title={this.selectInstanceRvContext.formTitle}
						onInstanceSelected={this.onInstanceSelected}
						rvContext = {this.selectInstanceRvContext}
					/>
				</div>
			);
		}
		
		let divMainStyle = getDefinedValue({...this.props.styles.divMain}, {} );
		divMainStyle.display = 'flex';
		if( ! divMainStyle.alignItems )
			divMainStyle.alignItems = 'center';
		return (
			<div style={divMainStyle}>
				{this.createLabel()}
				{valueHtml}
				{editToolsHtml}
			</div>
		)
	}
	
}

function ButtonSelectInstance({holder}) {
	let refNodes = holder.props.refNodes; 
	if( ! refNodes || refNodes.length == 0 ) {
		return null;
	} else if( refNodes.length == 1 ) {
		let disabled = ! holder.selectInstanceRvContext.form;
		return (
			<button type='button' className={stylesPlain.buttonIcon} style={{marginLeft: '10px'}}
				tooltip={holder.resource.buttonSelectTooltip} onClick={ () => {holder.selectInstance(null)} }
				disabled={disabled}
			>
				<img src={iconSelect} />
			</button>
		);
	} else {
		// TODO
		return null;
		/*
		var style = {};
		var button = {
			text: holder.resource.buttonSelect, 
			tooltip: holder.resource.buttonSelectTooltip, 
			icon: selectIcon
		};
		var menu = { items: [] };
		for(let refNode of listObjectRefNode) {
			menu.items.push( {
				title: refNode.selectInstanceFormTitle,
				method: () => { holder.selectInstance(refNode) }
			} )
		}
		return (
			<PopupMenu holder={holder} style={style} button={button} menu={menu} />
		);
		*/
	}
}

export default FieldReference;
