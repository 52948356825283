import React from 'react';
import stylesPlain from './plain.module.css';

import Field from './Field';

import { getDefinedValue, getDefinedItem } from '../../util/tools';
import { IET_SET_VALUE } from '../../util/realm/instance';

class FieldDate extends Field {

	/*
		styleInput !!! Obsolete
		styles
			input
	*/
	constructor(props) {
		super(props);
		this.state.inputValue = this.field ? this.field.value : null;
	}
	
	handleInstanceChange = async (event) => {
		if( event.eventType == IET_SET_VALUE && event.field == this.field) {
			this.setState({ inputValue: event.newValue });
		}
	}
	handleValueChange = e => {
		let value = e.target.value;
		if( value )
			value = value.slice(0,4) + value.slice(5,7) + value.slice(8,10);
		this.props.instance.setFieldValue( this.field, value )
	}
	
	render() {
		if( this.field == null ) {
			return this.createDivUnknownField();
		}
		let inputStyle = Object.assign({}, 
				getDefinedValue(this.props.styleInput, {}), // Obsolete 
				getDefinedItem(this.props, ['styles', 'input']));
		let readOnly = getDefinedValue( this.props.readOnly, false);
		let value = this.state.inputValue;
		if( value != null && value.length == 8) {
			value = value.slice(0,4) + '-' + value.slice(4,6) + '-' + value.slice(6,9);
		}
		return (
			<React.Fragment>
				{this.createLabel()}
				<input type='date' className={stylesPlain.inputFieldString} style={inputStyle} readOnly={readOnly} 
					value={value} onChange={this.handleValueChange}
				/>
			</React.Fragment>
		);
	}
}

export default FieldDate;