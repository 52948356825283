
export const getDefaultStyles = () => {
	return styles;
}

export const appendStyle = (baseStyle, styleToAppend) => {
	if( styleToAppend == null || styleToAppend == undefined )
		return;
	var keysToAppend = Object.keys(styleToAppend);
	for(let key of keysToAppend) {
		if( baseStyle.hasOwnProperty(key) ) {
			if( baseStyle[key] instanceof Object && styleToAppend[key] instanceof Object ) {
				appendStyle(baseStyle[key], styleToAppend[key]);
			} else {
				baseStyle[key] = styleToAppend[key];
			}
		} else {
			baseStyle[key] = styleToAppend[key];
		}
	}
}
export const appendListStyle = (baseStyle, listStyleToAppend) => {
	for( let style of listStyleToAppend) {
		appendStyle(baseStyle, style);
	}
}
export const mergeStyles = (listStyle) => {
	var baseStyle = {};
	appendListStyle(baseStyle, listStyle);
	return baseStyle;
}

const styles = {
	
	label: {
		color: 'darkBlue',
		fontStyle: 'italic'
	}
	
}