import React from 'react';
import stylesPlain from '../../components/plain/plain.module.css';

import PlainPage from '../../components/plain/PlainPage';
import LabelField from '../../components/plain/LabelField';
import FieldEnum from '../../components/plain/FieldEnum';
import FieldReference from '../../components/plain/FieldReference';
import FieldString from '../../components/plain/FieldString';
import FieldList_TableGroup from '../../components/plain/FieldList_TableGroup';
import FieldList_CardsPanel from '../../components/plain/FieldList_CardsPanel';

import { ORGANIZATION_PATH } from '../../util/routes';
import { FORM_ORGANIZATION_TABLE } from '../../util/consts/forms';

class PlainEBookSupplierPage extends PlainPage { 
		
	constructor(props) {
		super(props);
		this.formPath = 'ebook_supplier_plain';
		this.formTitle = 'Постачальник за програмою "єКнига"';
	}
	componentDidMount = async () => {
		this.init('EBookSupplier')
	}
	completeInit = async () => {
		if( this.instance.id == null ) {
			this.instance.rootField.value.state.value = 'registered';
		}
		return true;
	}

	merchantCardBuilder = (fieldMerchant, index) => {
		let card = '';
		if( fieldMerchant.value.eid.value )
			card += fieldMerchant.value.eid.value;
		card += ' - '
		if( fieldMerchant.value.mid.value )
			card += fieldMerchant.value.mid.value;
		card += ' - '
		if( fieldMerchant.value.mcc.value )
			card += fieldMerchant.value.mcc.value;
		return card;
	}

	renderBody() {
		var refOrganizationNodes = [
			{
				classId: 'Organization', plainFormPath: ORGANIZATION_PATH, 
				selectInstanceFormId: FORM_ORGANIZATION_TABLE,
				selectInstanceFormTitle: 'Організація'
			}
		]
		/*
		var tableTerminals = {
			columns: [
				{ columnId: 'mid', title: 'Mid', tdHeaderStyle: {width: '200px'}},
				{ columnId: 'tid', title: 'Tid', tdHeaderStyle: {width: '200px'}},
				{ columnId: 'area', title: 'Область', tdHeaderStyle: {width: '300px'}},
				{ columnId: 'address', title: 'Адреса', tdHeaderStyle: {width: '500px'}},
			],
			width: '1250px',
			styles: {
				table: {},
				thead: {textAlign: 'center', fontWeight: 'bold'},
				trHeader: {height: '43px'}
			}
		}
		var tableMids = {
			width: '300px',
			styles: {
				table: {},
			}
		}
		*/
		return (
			<div className={stylesPlain.divPlainMain}>
				{this.renderHeader()}
				
				{/* Body */}
				<div className={stylesPlain.divPlainBody} >
					<div style={{display: 'flex', alignItems: 'center'}}>
						<FieldReference instance={this.state.instance} path={'/orgRef'} plain={this}
							refNodes={refOrganizationNodes} 
							styles={{divMain: {marginRight: '30px'}}}
						/>
						<FieldEnum instance={this.state.instance} path={'/state'} plain={this} styleSelect={{}} />
					</div>
					{/* 
					<div style={{display: 'flex', marginTop: '10px'}}>
						<div>
							<FieldList_Strings instance={this.state.instance} path={'/mids'} plain={this}
								title={'Мерчант коди'} table={tableMids}
							/>
						</div>
						<div style={{marginLeft: '15px'}}>
							<FieldList_TableGroup instance={this.state.instance} path={'/terminals'} plain={this}
								title={'Термінали'} table={tableTerminals} cell={TerminalCell}
								disableMoveItems={true}
							/>
						</div>
					</div>
					*/}
					
					<div style={{marginTop: '20px', marginRight: '10px'}}>
						<FieldList_CardsPanel path={'/merchants'} instance={this.instance}
							plain={this} itemComponent={MerchantPanel} disableMoveItems={true}
							itemCardBuilder={this.merchantCardBuilder}
						/>
					</div>
					
					<div style={{marginTop: '10px'}}>
						<div>
							<LabelField instance={this.state.instance} path={'/comment'} />
						</div>
						<div>
							<FieldString instance={this.state.instance} path={'/comment'} plain={this}
								multiline={true} rows={5} cols={60} styleInput={{width: '99%'}}
							 />
						</div>
					</div>			
				</div>
			</div>
		)
	}
}

class MerchantPanel extends React.Component {

	tableTerminals = {
		columns: [
			{ columnId: 'tid', title: 'Код термінала (TID)', tdHeaderStyle: {width: '200px'}},
			{ columnId: 'area', title: 'Область', tdHeaderStyle: {width: '300px'}},
			{ columnId: 'address', title: 'Адреса', tdHeaderStyle: {width: '500px'}},
		]
	};
	/*
		plain
		instance
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
	}
	
	render() {
		let fieldGroup = this.props.fieldGroup;
		let plain = this.props.plain;
		let instance = this.props.instance;
		return (
			<div style={{flexGrow: '1', backgroundColor: 'lightGray'}}>
				<div style={{border: '1px solid green', margin: '10px 10px 10px 10px', padding: '10px'}}>
					<div style={{display: 'flex', alignItems: 'center'}}>
						<FieldString path={'eid'} instance={instance} parentField={fieldGroup} plain={plain}
							styles={{ input: { width: '200px', marginRight: '20px'}} }
						 />
						<FieldString path={'mid'} instance={instance} parentField={fieldGroup} plain={plain}
							styles={{ input: { width: '200px', marginRight: '20px'}} }
						 />
						<FieldEnum path={'mcc'} instance={instance} parentField={fieldGroup} plain={plain} 
							styles={{ select: { marginRight: '20px'} }} />
					</div>
					<div style={{marginTop: '15px'}}>
						<FieldList_TableGroup path={'terminals'} instance={instance} parentField={fieldGroup} plain={plain}
							table={this.tableTerminals} cell={TerminalCell}
							disableMoveItems={true}
						/>
					</div>
				</div>
			</div>
		)
	}
}

class TerminalCell extends React.Component {

	/*
		columnId
		rowIndex
		plain
		fieldGroup
	*/
	constructor(props) {
		super(props);
	}
	
	render() {
		var columnId = this.props.columnId;
		var fieldGroup = this.props.fieldGroup;
		var plain = this.props.plain;

		if( columnId == 'mid' ) {
			return (
				<FieldString instance={this.props.instance} path={'mid'} plain={plain} parentField={fieldGroup} 
					ignoreTitle={true} styleInput={{width: '95%'}} 
				/>
			);
		}
		if( columnId == 'tid' ) {
			return( 
				<FieldString instance={this.props.instance} path={'tid'} plain={plain} parentField={fieldGroup} 
					ignoreTitle={true} styleInput={{width: '95%'}} 
				/>
			);
		}
		if( columnId == 'area' ) {
			return (
				<FieldEnum instance={this.props.instance} path={'area'} plain={plain} parentField={fieldGroup} 
					ignoreTitle={true} styleSelect={{width: '95%'}}
				/>
			);
		}
		if( columnId == 'address' ) {
			return( 
				<FieldString instance={this.props.instance} path={'address'} plain={plain} parentField={fieldGroup} 
					ignoreTitle={true} styleInput={{width: '95%'}}
				/>
			);
		}
		return null;
	}

}

export default PlainEBookSupplierPage;
