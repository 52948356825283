import React, { Component } from 'react';
import styles from '../../components/rateview/RateView.module.css';
import config from '../../config/config';
import NavBar from '../../components/navigation/NavBar';
import RateView from '../../components/rateview/RateView';
import ButtonIconText from '../../components/button/ButtonIconText';
import ClassifierImport from '../../components/exim/ClassifierImport';
import { getUserInfo, canCreateObject, canPerformAction } from '../../util/localStorage';
import { getCurrentResource } from '../../util/tools';

import { MODE_TREE } from '../../components/rateview/RateView';
import { FORM_TOPIC_TABLE } from '../../util/consts/forms';
import { ACTION_CLASSIFIER_IMPORT } from '../../util/consts/actions';

import addIcon from '../../assets/icons/png24x24/Add.png';

class TopicListPage extends Component {
	
	constructor(props) {
		super(props);
		this.rvComponent = null;

		this.resource = getCurrentResource().pages.tableform.TopicList;
		var paramCacheId = props.match.params.param;

		this.rvContext = {
			form: FORM_TOPIC_TABLE,
			data: []
		};
		this.rvOptions = {
			form: FORM_TOPIC_TABLE,
			formTitle: this.resource.formTitle,
			tableTreeMode: MODE_TREE,
			buttonAdd: (<ButtonAdd holder={this} />),
//			leftSideTools: (<LeftSideTools holder={this} />),
			controlPanelTools: (<ControlPanelTools holder={this} />),
			paramCacheId: paramCacheId,
			transition: null
		}
	}

	handleSubmit = (event) => {
		var ids = this.rvComponent.getSelectedIds();
		if( ids.length > 0 )
			this.setState({selectedId: ids[0]});
		// event.preventDefault();
	}
	
	addTopic = () => {
		var param = { id: 0, masterId: 0 };
		var ids = this.rvComponent.getSelectedIds();
		if( ids.length > 0 )
			param.masterId = ids[0];
		var strParam = JSON.stringify(param);
		var encodedParam = encodeURIComponent(strParam);
		
		var form = this.rvComponent.getPlainFormEdit();
		var url = `${config.PATH}/` + form.formId + '/0/' + encodedParam;
		window.open(url);
	}
	
	render() {
		return (
			<React.Fragment>
				<NavBar app={this.props.app} />
				<RateView app={this.props.app} holder={this} rvOptions={this.rvOptions} rvContext={this.rvContext} />
			</React.Fragment>
		)
	}
}

function LeftSideTools(props) {
	if( ! canPerformAction( ACTION_CLASSIFIER_IMPORT ) )
		return ( null );
	return (
		<div style={{textAlign: "left"}}>
			<ClassifierImport holder={props.holder} mode='leftSide' />
		</div>			
	);
}
function ControlPanelTools(props) {
	if( ! canPerformAction( ACTION_CLASSIFIER_IMPORT ) )
		return ( null );
	return (
		<div style={{textAlign: 'left', marginLeft: '20px'}}>
			<ClassifierImport holder={props.holder} mode='controlPanel' />
		</div>			
	);
}

function ButtonAdd(props) {
	if( ! props.holder.rvComponent )
		return null;
	var form = props.holder.rvComponent.getPlainFormEdit();

	var disabled = ! canCreateObject(props.holder.rvComponent.formInfo.masterClass);
	//disabled = disabled || props.holder.rvComponent.state.tableTreeMode == MODE_TREE; 
	disabled = disabled || (form == null); 

	return (
		<ButtonIconText 
			styles={styles} onClick={props.holder.addTopic}
			text={'Add'} tooltip={'Add topic'}
			disabled={disabled} icon={addIcon}
		/>
	);
}

export default TopicListPage;
