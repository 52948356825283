import React, { Component } from 'react';
import NavBar from '../../components/navigation/NavBar';
import RateView from '../../components/rateview/RateView';
import { getCurrentResource } from '../../util/tools';
import { FORM_LIBRARY_TABLE } from '../../util/consts/forms';
import { ACTION_LIBRARY_EXPORT_API_URL } from '../../util/restapi';
import { ACTION_LIBRARY_EXPORT } from '../../util/consts/actions';

class LibraryListPage extends Component {
	constructor(props) {
		super(props);

		this.resource = getCurrentResource().pages.tableform.LibraryList;
		var paramCacheId = props.match.params.param;

		this.rvContext = {
			form: FORM_LIBRARY_TABLE,
			data: []
		}
		this.rvOptions = {
			form: FORM_LIBRARY_TABLE,
			formTitle: this.resource.formTitle,
			paramCacheId: paramCacheId,
			transition: null
		};
	}

	render() {
		return (
			<React.Fragment>
				<NavBar app={this.props.app} />
				<RateView rvContext={this.rvContext} rvOptions={this.rvOptions} app={this.props.app} />
			</React.Fragment>
		)
	}
	
}

export default LibraryListPage;
