import React, { Component } from 'react';
import NavBar from '../../components/navigation/NavBar';
import RateView from '../../components/rateview/RateView';
import { getCurrentResource } from '../../util/tools';
import { FORM_ORGANIZATION_TABLE } from '../../util/consts/forms';

class OrganizationListPage extends Component {
	constructor(props) {
		super(props);

		this.resource = getCurrentResource().pages.tableform.OrganizationList;
		var paramCacheId = props.match.params.param;

		this.rvContext = {
			form: FORM_ORGANIZATION_TABLE,
			data: []
		}
		this.rvOptions = {
			form: FORM_ORGANIZATION_TABLE,
			formTitle: this.resource.formTitle,
			paramCacheId: paramCacheId,
			transition: null
		};
	}
	
	render() {
		return (
			<React.Fragment>
				<NavBar app={this.props.app} />
				<RateView rvContext={this.rvContext} rvOptions={this.rvOptions} app={this.props.app}  />
			</React.Fragment>
		)
	}
	
}

export default OrganizationListPage;
