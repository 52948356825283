import React from 'react';

import { getDefinedItem } from '../../util/tools';

class TabbedPane extends React.Component {

	/*
		tabs 
			tabId
			tabTitle
		createTab
		dirtyCounter
		styles
			divMain
	*/
	constructor(props) {
		super(props);
		let tabRows = [];
		for(let i=props.tabs.length-1; i >= 0; i--) {
			tabRows.push( props.tabs[i] );
		}
		this.state = {
			tabRows: tabRows,
			activeTabIndex: 0
		}
	}

	setActiveTab = (rowId, tabIndex) => {
		if( rowId == this.state.tabRows[this.state.tabRows.length-1].rowId ) {
			this.setState({activeTabIndex: tabIndex});
			return;
		}
		
		let tabRows = [];
		let lastRow = null;
		for(let i=this.props.tabs.length-1; i >= 0; i--) {
			let row = this.props.tabs[i];
			if( row.rowId == rowId )
				lastRow = row;
			else
				tabRows.push( row );
		}
		tabRows.push( lastRow );
		this.setState({tabRows: tabRows, activeTabIndex: tabIndex});
	}
	
	render() {
		let tabRows = this.state.tabRows;
		let tabHtml = null;
		let divActivePanelHtml = null;
		if( tabRows.length > 0 ) {
			let activeRow = tabRows[ tabRows.length-1 ];
			tabHtml = tabRows.map((tabRow, rowIndex)=> {
				let tabRowHtml = tabRow.tabs.map( (tabItem, itemIndex) => {
					let activeTab = ( rowIndex == tabRows.length-1 && this.state.activeTabIndex == itemIndex ); 
					let style = activeTab ? {...localStyles.divActiveTab} : {...localStyles.divNotActiveTab};
					style.flexGrow = '1';
					return (
						<div key={itemIndex} style={style} onClick={ ()=>{this.setActiveTab(tabRow.rowId, itemIndex)} }>
							{tabItem.tabTitle}
						</div>
					)
				} )
				return (
					<div key={tabRow.rowId} style={{display: 'flex', background: 'lightgray', padding: '5px 5px 0 5px'}}>
						{tabRowHtml}
					</div>
				);
			});
			let activeTabId = activeRow.tabs[ this.state.activeTabIndex ].tabId;
			divActivePanelHtml = (
				<div>
					{this.props.createTab(activeTabId)}
				</div>					
			)
		}
		let divMainStyle = getDefinedItem(this.props, ['styles', 'divMain'], {});
		return (
			<div style={divMainStyle}>
				{tabHtml}
				{divActivePanelHtml}
			</div>
		);
	}
}

const localStyles = {
	divActiveTab: {
		margin: '5px 0px 0 0px', /* top | right | bottom | left */
		padding: '5px 10px 5px 10px',
		fontWeight: 'bold',
		borderLeft: '1px solid grey',
		borderRight: '1px solid grey',
		borderTop: '1px solid grey',
		borderRadius: '20px 0px 0 0',
		background: 'rgb(240,240,240)',
		cursor: 'pointer',
		textAlign: 'center'
	},	
	divNotActiveTab: {
		margin: '5px 0px 0 0px',
		padding: '5px 10px 5px 10px',
		flexGrow: '1',
		borderLeft: '1px solid grey',
		borderRight: '1px solid grey',
		borderTop: '1px solid grey',
		borderRadius: '20px 0px 0 0',
		background: 'lightgray',
		cursor: 'pointer',
		textAlign: 'center'
	},	
};

export default TabbedPane; 