import React from 'react';

import FieldString from '../../../components/plain/FieldString';
import FieldEnum from '../../../components/plain/FieldEnum';
import FieldList_CardsPanel from '../../../components/plain/FieldList_CardsPanel';

class TabBrNames extends React.Component {
	
	constructor(props) {
		super(props);
	}

	titleCardBuilder = (fieldTitle, index) => {
		let plain = this.props.plain;
		let card = '';
		
		let typeText = plain.realm.getEnumerationItemText( plain.enumTitleType, fieldTitle.value.titleType.value);
		if( typeText )
			card += '[' + typeText + ']; ';
		
		let langText = plain.realm.getEnumerationItemText( plain.enumLanguage, fieldTitle.value.language.value);
		if( langText )
			card += '[' + langText + ']; ';
		
		if( fieldTitle.value.mainName.value )
			card += fieldTitle.value.mainName.value;
		return card;
	}
	
	render() {
		let plain = this.props.plain;
		let instance = this.props.instance;
		let parentField = this.props.parentField;
		if( ! parentField ) {
			parentField = instance.rootField;
		}
		return (
			<div style={{padding: '10px' }}>
				<FieldList_CardsPanel path={'title'} instance={instance} parentField={parentField} plain={plain} 
					itemComponent={TitlePanel} itemCardBuilder={this.titleCardBuilder}
					ignoreTitle={true}
					styles={{
						divCardsBlock: {
							marginRight: '10px',
							backgroundColor: 'rgb(240,240,240)',
							minWidth: '300px', maxWidth: '300px'
						},
						divCard: {
							marginTop: '3px',
							padding: '7px',
							cursor: 'pointer',
							backgroundColor: 'lightGray',
							minHeight: '18px'
						}
					}}
				/>
			</div>
		);
	}
}

class TitlePanel extends React.Component {

	/*
		plain
		instance
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
	}
	
	render() {
		let fieldGroup = this.props.fieldGroup;
		let plain = this.props.plain;
		let instance = this.props.instance;
		return (
			<div style={{flexGrow: '1', backgroundColor: 'lightGray'}}>
				<div style={{margin: '10px', padding: '10px', border: '1px solid gray'}}>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldEnum instance={instance} path={'titleType'} parentField={fieldGroup} plain={plain}
							styles={{ select: {flexGrow: '1', marginRight: '20px'}}}
						/>
						<FieldEnum instance={instance} path={'language'} parentField={fieldGroup} plain={plain}
							styles={{ select: {flexGrow: '1'}}}
						/>
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'series'} parentField={fieldGroup} plain={plain}
							styles={{ input: {flexGrow: '1'}}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'mainName'} parentField={fieldGroup} plain={plain}
							styles={{ input: {flexGrow: '1'}}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'shortName'} parentField={fieldGroup} plain={plain}
							styles={{ input: {flexGrow: '1', marginRight: '20px'}}}
						 />
						<FieldEnum instance={instance} path={'scriptOfTitle'} parentField={fieldGroup} plain={plain} />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'subName'} parentField={fieldGroup} plain={plain}
							styles={{ input: {flexGrow: '1'}}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'volYear'} parentField={fieldGroup} plain={plain}
							styles={{ input: {width: '100px', marginRight: '20px'}}}
						 />
						<FieldString instance={instance} path={'volName'} parentField={fieldGroup} plain={plain}
							styles={{ input: {flexGrow: '1'}}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'partNum'} parentField={fieldGroup} plain={plain}
							styles={{ input: {width: '100px', marginRight: '20px'}}}
						 />
						<FieldString instance={instance} path={'partName'} parentField={fieldGroup} plain={plain}
							styles={{ input: {flexGrow: '1'}}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'version'} parentField={fieldGroup} plain={plain}
							styles={{ input: {flexGrow: '1', marginRight: '20px'}}}
						 />
						<FieldString instance={instance} path={'period'} parentField={fieldGroup} plain={plain}
							styles={{ input: {flexGrow: '1'}}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'nameInfo'} parentField={fieldGroup} plain={plain}
							styles={{ input: {flexGrow: '1'}}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'other'} parentField={fieldGroup} plain={plain}
							styles={{ input: {flexGrow: '1'}}}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'comment'} parentField={fieldGroup} plain={plain}
							styles={{ input: {flexGrow: '1'}}}
						 />
					</div>
				</div>
			</div>
		)
	}
}

export default TabBrNames;