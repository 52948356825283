import React, { Component } from 'react';
import Modal from 'react-responsive-modal';

import NavBar from '../../components/navigation/NavBar';
import RateView from '../../components/rateview/RateView';

import { getCurrentResource } from '../../util/tools';
import { canPerformAction } from '../../util/localStorage';
import { performApiDownloadRequest, ACTION_AUTHORITY_PUBLISHER_EXPORT_API_URL } from '../../util/restapi';
import { ACTION_AUTHORITY_PUBLISHER_EXPORT } from '../../util/consts/actions';
import { FORM_AUTHORITY_PUBLISHER_TABLE } from '../../util/consts/forms';

import iconExport from '../../assets/icons/png24x24/send_message.png';

const MODAL_MODE_ACTIVE = 'a';
const MODAL_MODE_WAIT = 'w';
const MODAL_MODE_ERROR = 'e';

class AuthorityPublisherListPage extends Component {
	
	constructor(props) {
		super(props);

		this.refExportModal = React.createRef();
		this.state = {
			isLocalExportModalOpen: false,
			isLocalImportModalOpen: false,
		}

		this.resource = getCurrentResource().pages.tableform.AuthorityPublisherList;
		var paramCacheId = props.match.params.param;

		this.rvContext = {
			form: FORM_AUTHORITY_PUBLISHER_TABLE,
			data: []
		}
		this.rvOptions = {
			form: FORM_AUTHORITY_PUBLISHER_TABLE,
			formTitle: this.resource.formTitle,
			controlPanelTools: this.controlPanelTools(),
			paramCacheId: paramCacheId,
			transition: null
		};
	}

	openExportModal = () => {
		if( ! this.rvContext.data )
			return;
		
		var listInstanceId = [];
		for( var row of this.rvContext.data ) {
			if( row.checked ) {
				listInstanceId.push( row.id );
			}
		}
		if( listInstanceId.length == 0 )
			return;
		
		this.refExportModal.current.initModal(listInstanceId);
		this.setState({ isLocalExportModalOpen: true});	
	}
	closeExportModal = () => {
		this.setState({ isLocalExportModalOpen: false });	
	}

	controlPanelTools = () => {
		return (
			<div style={{textAlign: 'left', marginLeft: '20px'}}>
				<ControlPanelButtonExport holder={this} />
			</div>			
		);
	}
	
	render() {
		return (
			<React.Fragment>
				<NavBar app={this.props.app} />
				<RateView rvContext={this.rvContext} rvOptions={this.rvOptions} app={this.props.app} />
				<ExportModal ref={this.refExportModal} page={this} 
					isModalOpen={this.state.isLocalExportModalOpen} 
					close={this.closeExportModal} 
				/>
			</React.Fragment>
		)
	}
	
}

class ExportModal extends React.Component {

	constructor(props) {
		super(props);
		this.listInstanceId = [];
		this.errorMessage = '';
		this.errorLog = '';
		this.state = {
			modalMode: MODAL_MODE_ACTIVE,
			fileName: '',
			format: "uap",
		};
	}
	initModal = (listInstanceId) => {
		this.listInstanceId = listInstanceId;
		this.errorMessage = '';
		this.errorLog = '';
		this.setState({
			modalMode: MODAL_MODE_ACTIVE,
			fileName: '',
			format: "uap",
		});
	}

	handleInputEdit = (e) => {
		const { name, value } = e.target;
		if( name == 'filename' )
			this.setState({fileName: value});
	};
	handleFormatSelectClick = (e) => {
		this.setState({format: e.target.value});
	}
	startExport = async () => {
		this.setState({modalMode: MODAL_MODE_WAIT})
		
		var exportOptions = {
			format: this.state.format,
			listInstanceId: this.listInstanceId
		};
		var response = await performApiDownloadRequest(
			ACTION_AUTHORITY_PUBLISHER_EXPORT_API_URL, exportOptions, 'text/xml');
		if( response.success ) {
			var fileName = this.state.fileName;
			if( ! fileName )
				fileName = 'export_ap.xml';
			const blobUrl = window.URL.createObjectURL(response.blob);
			const link = document.createElement('a');
			link.href = blobUrl;
			link.download = fileName;
			document.body.appendChild(link);
			link.click();
			this.setState({modalMode: MODAL_MODE_ACTIVE})
			this.props.page.closeExportModal();
		} else {
			this.errorMessage = response.message; 
			this.errorLog = response.log;
			this.setState({modalMode: MODAL_MODE_ERROR})
		}
	}
	tryAgain = () => {
		this.setState({
			modalMode: MODAL_MODE_ACTIVE,
		});
	}

	render() {
		var resource = this.props.page.resource;
		if( this.state.modalMode == MODAL_MODE_ERROR ) {
			var modalBodyHtml = (
				<React.Fragment>
					<div style={{color: 'red', textAlign: 'center', padding: '30px', fontSize: '16pt'}}>
						{this.errorMessage}
					</div>
					<div style={localStyles.divButtons}>
						<button type='button' onClick={this.tryAgain} >
							{resource.buttonTryAgain}
						</button>
					</div>
				</React.Fragment>
			);
		} else {
			var divWaiting = this.state.modalMode == MODAL_MODE_WAIT ?
				(<div style={localStyles.divWaiting}>{resource.waiting}</div>) : null;  
			var optionsHtml = formatList.map( (format, index) => {
				return (<option key={index} value={format.value}>{format.label}</option>);
			} );
			var modalBodyHtml = (
				<React.Fragment>
					<div style={localStyles.divParameters}>
						<div>
							{resource.modalExportLabelFile} 
							<input type="text" name="filename" style={{marginLeft: '10px', width: '400px'}}
								value={this.state.fileName} onChange={ this.handleInputEdit}
							/>
						</div>
						<div style={{ display: "flex", alignItems: 'center', marginTop: '20px' }}>
							<div>{resource.modalExportLabelFormat}</div>
							<div style={{ marginLeft: "10px"}}>
								<select value={this.state.format} onChange={this.handleFormatSelectClick}>
									{optionsHtml}
								</select>
							</div>
		 				</div>
					</div>
					{divWaiting}
					<div style={localStyles.divButtons}>
						<button type='button' title={resource.buttonExportTooltip}
							onClick={this.startExport} disabled={this.state.modalMode == MODAL_MODE_WAIT}
						>
							{resource.buttonExport}
						</button>
					</div>
				</React.Fragment>
			);
		}
		return (
			<Modal 
				open={this.props.isModalOpen} onClose={this.props.close} center 
				styles={styleModal} closeOnOverlayClick={false} animationDuration={1}
			>
				<div>
					<div style={localStyles.divHeader}>
						{resource.modalExportTitle}
					</div>
					{modalBodyHtml}
				</div>
			</Modal>
		);
	}
}

function ControlPanelButtonExport(props) {
	
	if( canPerformAction( ACTION_AUTHORITY_PUBLISHER_EXPORT ) ) {
		return (
			<button type='button' style={{marginLeft: '5px', width: '36px', height: '30px'}}
				 onClick={props.holder.openExportModal}
				 title={props.holder.resource.buttonExportTooltip} 
			>
				<img src={iconExport} />
			</button>			
		);
	} else {
		return null;
	}
}

const styleModal = {
	modal: {
		background: '#ffffff',
		padding: '0px',
		borderRadius: '21px',
		maxWidth: '500px',
		width: '100%',
	},
	closeButton: {
		top: '10px',
		right: '10px',
		fill: '#FF0000',
		outline: 'none',
	},
};

const localStyles = {
	divHeader: {
		fontSize: '16pt',
		textAlign: 'center',
		padding: '10px',
		borderRadius: '21px 21px 0 0',
		backgroundColor: '#F0F0F0'
	},
	divParameters: {
		marginTop: '10px',
		padding: '10px'
	},
	divWaiting: {
		textAlign: 'center',
		padding: '7px'
	},
	divButtons: {
		marginTop: '7px',
		padding: '10px',
		marginRight: '15px',
		textAlign: 'right'
	}
};

const formatList = [
	{ value: "uap", label: "UAP XML" },
];

export default AuthorityPublisherListPage;
