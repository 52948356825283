import React from 'react';
import stylesPlain from './plain.module.css';

import Field from './Field';

import { getDefinedValue, getDefinedItem, validDateTime } from '../../util/tools';
import { IET_SET_VALUE } from '../../util/realm/instance';

class FieldDatetime extends Field {

	ignoreInstanceChange = false;
	validator = null;

	/*
		styleInput !!! Obsolete
		styles
			input
		validator {
			isValueValid - function
			validBackground
			invalidBackground
		}
	*/
	constructor(props) {
		super(props);
		this.validator = props.validator;
		if( ! this.validator ) 
			this.validator = {
				isValueValid: validDateTime,
				validBackground: 'white',
				invalidBackground: 'lightYellow'
			}
		this.state.inputValue = this.field ? this.field.value : '';
		this.state.valueIsValid = true;
	}

	handleInstanceChange = async (event) => {
		if( this.ignoreInstanceChange )
			return;
		if( event.eventType == IET_SET_VALUE && event.field == this.field) {
			this.setState({ inputValue: event.newValue, valueIsValid: true });
		}
	}
	handleValueChange = e => {
		let value = e.target.value;
		let valueIsValid = this.validator.isValueValid( value ); 
		if( valueIsValid ) {
			this.props.instance.setFieldValue( this.field, value )
		} else {
			this.ignoreInstanceChange = true;
			this.props.plain.instance.setFieldValue( this.field, null )
			this.ignoreInstanceChange = false;
			this.setState({ inputValue: value, valueIsValid: valueIsValid });
		}
	}

	render() {
		if( this.field == null ) {
			return this.createDivUnknownField();
		}
		let inputStyle = Object.assign({}, 
				getDefinedValue(this.props.styleInput, {}), // Obsolete 
				getDefinedItem(this.props, ['styles', 'input']));
		inputStyle.backgroundColor = this.state.valueIsValid ? this.validator.validBackground : this.validator.invalidBackground;
		let readOnly = getDefinedValue( this.props.readOnly, false);
		
		return (
			<React.Fragment>
				{this.createLabel()}
				<input type='text' className={stylesPlain.inputFieldString} style={inputStyle} readOnly={readOnly}
					value={this.state.inputValue} onChange={this.handleValueChange}
				/>
			</React.Fragment>
		);
	}
}

export default FieldDatetime;
