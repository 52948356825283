import React from 'react';

import FieldString from '../../../components/plain/FieldString';
import FieldEnum from '../../../components/plain/FieldEnum';

class TabBrParameters extends React.Component {
	
	constructor(props) {
		super(props);
	}
	
	render() {
		var plain = this.props.plain;
		var instance = this.props.instance;
		return (
			<div style={{padding: '20px 20px 20px 20px' }}>
				<div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
					<FieldString instance={instance} path={'/parameter/recordId'} plain={plain} 
						styles={{ input: {flexGrow: '1', marginRight: '20px'}}}
					/>
					<FieldString instance={instance} path={'/parameter/number'} plain={plain} 
						styles={{ input: {flexGrow: '1'}}}
					/>
				</div>
				<div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
					<FieldString instance={instance} path={'/parameter/versionId'} plain={plain} 
						styles={{ input: {flexGrow: '1', marginRight: '20px'}}}
					/>
					<FieldString instance={instance} path={'/parameter/date'} plain={plain} 
						styles={{ input: {flexGrow: '1'}}}
					/>
				</div>
				<div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
					<FieldEnum instance={instance} path={'/parameter/country'} plain={plain} 
						styles={{ select: {flexGrow: '1', marginRight: '20px'}}}
					/>
					<FieldEnum instance={instance} path={'/parameter/agencyType'} plain={plain} 
						styles={{ select: {flexGrow: '1'}}}
					/>
				</div>
				<div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
					<FieldString instance={instance} path={'/parameter/agency'} plain={plain} 
						styles={{ input: {flexGrow: '1'}}}
					/>
				</div>
			</div>
		)
	}
}

export default TabBrParameters; 