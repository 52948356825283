import React from 'react';

import Label from '../../../components/plain/Label';
import FieldString from '../../../components/plain/FieldString';
import FieldEnum from '../../../components/plain/FieldEnum';
import FieldDate from '../../../components/plain/FieldDate';
import FieldList_TableGroup from '../../../components/plain/FieldList_TableGroup';
import stylesPlain from '../../../components/plain/plain.module.css';

import {getDefinedItem} from '../../../util/tools';

import iconCollapse from '../../../assets/icons/png24x24/collapse_silver.png';
import iconExpandFilled from '../../../assets/icons/png24x24/expand.png';
import iconExpandEmpty from '../../../assets/icons/png24x24/expand_grey.png';

class DivFileChar extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			isCollapsed: true
		}
	}
	
	collapse = () => {
		this.setState({isCollapsed: true})
	}
	expand = () => {
		this.setState({isCollapsed: false})
	}
	isGroupEmpty = () => {
		let fieldGroup = this.props.fieldGroup;
		if( fieldGroup.value.resourceURI.value )
			return false;
		if( fieldGroup.value.date.value.length > 0 )
			return false;
		if( fieldGroup.value.fileFormat.value )
			return false;
		if( fieldGroup.value.downloadSizeByte.value )
			return false;
		if( fieldGroup.value.md5.value )
			return false;
		if( fieldGroup.value.sha256.value )
			return false;
		if( fieldGroup.value.iscc.value )
			return false;
		return true;
	}
	
	render() {
		let fieldGroup = this.props.fieldGroup;
		let plain = this.props.plain;
		let instance = this.props.instance;
		let tableDate = { 
			columns: [
				{ columnId: 'dateRole', title: 'Роль', tdHeaderStyle: {width: '20%'}},
				{ columnId: 'dateValue', title: 'Дата', tdHeaderStyle: {width: '20%'}},
			]
		}
		
		let divMainStyle = Object.assign(
			{padding: '10px', border: '1px solid blue'}, 
			getDefinedItem(this.props, ['styles', 'divMain'], {}))

		let iconExpand = this.isGroupEmpty() ? iconExpandEmpty : iconExpandFilled; 
		
		if( this.state.isCollapsed ) {
			return (
				<div style={divMainStyle}>
					<div style={{display: 'flex', alignItems: 'center'}}>
						<button type='button' className={stylesPlain.buttonIcon} style={{marginRight: '15px'}}
							onClick={this.expand}
						>
							<img src={iconExpand} />
						</button>
						<Label title={'Характеристики файла'} />
					</div> 
				</div>
			);
		} else {
			return (
				<div style={divMainStyle}>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<button type='button' className={stylesPlain.buttonIcon} style={{marginRight: '15px'}}
							onClick={this.collapse}
						>
							<img src={iconCollapse} />
						</button>
						<FieldString instance={instance} path={'resourceURI'} parentField={fieldGroup} plain={plain}
							styles={{ divMain: {flexGrow: '1'}}}
						 />
					</div> 
					<div style={{flexGrow: '1', marginBottom: '15px'}}>
						<FieldList_TableGroup instance={instance} path={'date'} parentField={fieldGroup} plain={plain} 
							plainDirtyCounter={plain.state.plainDirtyCounter}
							labelEmptyToHeader={true}
							table={tableDate} cell={DateCell}
						/>
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'fileFormat'} parentField={fieldGroup} plain={plain}
							styles={{ input: {width: '50px', flexGrow: '1', marginRight: '20px'}}}
						 />
						<FieldString instance={instance} path={'downloadSizeByte'} parentField={fieldGroup} plain={plain}
							styles={{ input: {width: '50px', flexGrow: '1'}}} readOnly={true}
						 />
					</div>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						<FieldString instance={instance} path={'md5'} parentField={fieldGroup} plain={plain}
							styles={{ input: {width: '100px', flexGrow: '1', marginRight: '20px'}}} readOnly={true}
						 />
						<FieldString instance={instance} path={'sha256'} parentField={fieldGroup} plain={plain}
							styles={{ input: {width: '100px', flexGrow: '1', marginRight: '20px'}}} readOnly={true}
						 />
						<FieldString instance={instance} path={'iscc'} parentField={fieldGroup} plain={plain}
							styles={{ input: {width: '100px', flexGrow: '1'}}} readOnly={true}
						 />
					</div>
				</div>
			);
		}
	}
}

class DateCell extends React.Component {

	/*
		plainDirtyCounter
		instance
		columnId
		rowIndex
		plain
		fieldGroup
	*/
	constructor(props) {
		super(props);
	}
	
	render() {
		let columnId = this.props.columnId;
		let fieldGroup = this.props.fieldGroup;
		let plain = this.props.plain;
		let instance = this.props.instance;

		if( columnId == 'dateRole' ) {
			return ( 
				<FieldEnum instance={instance} path={'dateRole'} plain={plain} parentField={fieldGroup} 
					ignoreTitle={true} styles={{ select: {width: '98%'}}} 
				/>
			);
		}
		if( columnId == 'dateValue' ) {
			return (
				<FieldDate instance={instance} path={'dateValue'} plain={plain} parentField={fieldGroup} 
					ignoreTitle={true} styles={{ input: {width: '95%'}}}
				/>
			);
		}
		return null;
	}
}

export default DivFileChar; 
