import React from 'react';
import stylesPlain from './plain.module.css';

import FieldList from './FieldList';
import SelectInstance from '../../modal/selectinstance/SelectInstance';

import { getDefinedItem, getCurrentResource } from '../../util/tools';
import { performApiRequest, MASTER_LOAD_REF_LIST_API_URL } from '../../util/restapi';

import iconAdd from '../../assets/icons/png24x24/Add.png';

class FieldList_Reference extends FieldList {

	resource = null;
	selectInstanceRvContext = {
		form: '',
		formTitle: ''
	}
	refNodes = [];

	/*
		refNodes
	*/
	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.plain.FieldList_Reference;		
		this.init();
		let refNodes = this.props.refNodes;
		if( ! refNodes )
			refNodes = this.field.model.refNodes;
		if( ! refNodes )
			refNodes = [];
		if( refNodes.length > 0 ) {
			this.selectInstanceRvContext.form = this.props.refNodes[0].selectInstanceFormId;
			this.selectInstanceRvContext.formTitle = this.props.refNodes[0].selectInstanceFormTitle;
		}
		this.refNodes = refNodes;
		this.state.isSelectInstanceModalOpen = false;
	}

	selectInstance = (refNode) => {
		if( refNode ) {
			this.selectInstanceRvContext.form = refNode.selectInstanceFormId;
			this.selectInstanceRvContext.formTitle = refNode.selectInstanceFormTitle;
		}
		this.setState({ isSelectInstanceModalOpen: true });
	};
	onInstanceSelected = async (ids) => {
		if( ids.length == 0 )
			return;
		let request = {
			listObjectId: ids
		}
		let response = await performApiRequest(MASTER_LOAD_REF_LIST_API_URL, request);
		if( ! response.success ) {
			this.props.plain.app.showResponse( response );
			return;
		}
		this.closeSelectInstance();
		for( let ref of response.data.listObjectRef ) {
			let unique = true;
			for( let item of this.field.value ) {
				if( item.value && item.value.id == ref.id ) {
					unique = false;
					break;
				}
			}
			if( unique ) {
				let newItem = this.props.instance.addListItem( this.field, null );
				this.props.instance.setFieldValue(newItem, ref);
			}
		}
	}
	closeSelectInstance = () => {
		this.setState({ isSelectInstanceModalOpen: false });
	}

	createButtonAddHtml() {
		return <ButtonSelectInstance holder={this} />
	}

	render() {
		if( ! this.field ) {
			return this.createDivUnknownField();			
		}
		
		let headerHtml = this.createHeaderHtml();
		let tableHtml = null;
		
		if( this.field.value.length == 0 ) {
			if( ! this.props.labelEmptyToHeader ) {
				tableHtml = (
					<div style={{fontStyle: 'italic', color: 'grey'}}>
						{this.resource.labelEmptyTable}
					</div>
				);
			}
		} else {
			let rowsHtml = this.field.value.map((itemField, index) => {
				let valueHtml = null;
				let ref = itemField.value;
				if( ref ) {
					let refNode = null;
					for(let item of this.refNodes) {
						if( item.classId == '*' || item.classId == ref.className ) {
							refNode = item;
							break;
						}
					}
					if( refNode == null ) {
						valueHtml = (<span>{ref.card}</span>);
					} else {
						let url = refNode.plainFormPath + '/' + ref.id;
						valueHtml = (<a href={url}>{ref.card}</a>);
					}
				} 
					
				let trStyle = {backgroundColor: 'lightGray'};
				if( index == this.state.selectedIndex )
					trStyle.backgroundColor = 'lightBlue';
				
				return (
					<tr key={itemField.fieldId} style={trStyle} onClick={ () => {this.handleRowClick(index)} }>
						<td>
							{valueHtml}
						</td>
					</tr>
				)
			});
			let tableStyle = {...getDefinedItem( this.props, ['table', 'styles', 'table'], {})};
			tableStyle.width = getDefinedItem( this.props, ['table','width'], '100%');
			tableHtml = (
				<table border='1' style={tableStyle}>
					<tbody>
						{rowsHtml}
					</tbody>
				</table>
			);
		}
		
		let divMainStyle = getDefinedItem(this.props, ['styles', 'divMain'], {});
		return (
			<div style={divMainStyle}>
				{headerHtml}
				{tableHtml}
				<SelectInstance 
					isModalOpen={this.state.isSelectInstanceModalOpen} 
					closeModal={this.closeSelectInstance}
					title={this.selectInstanceRvContext.formTitle}
					onInstanceSelected={this.onInstanceSelected}
					rvContext = {this.selectInstanceRvContext}
				/>
			</div>
		);
	}
}

function ButtonSelectInstance({holder}) {
	let refNodes = holder.refNodes; 
	if( ! refNodes || refNodes.length == 0 ) {
		return null;
	} else if( refNodes.length == 1 ) {
		let disabled = ! holder.selectInstanceRvContext.form;
		return (
			<button type='button' className={stylesPlain.buttonIcon}
				tooltip={holder.resource.buttonSelectTooltip} onClick={ () => {holder.selectInstance(null)} }
				disabled={disabled} style={{marginRight: '5px'}}
			>
				<img src={iconAdd} />
			</button>
		);
	} else {
		// TODO
		return null;
		/*
		var style = {};
		var button = {
			text: holder.resource.buttonSelect, 
			tooltip: holder.resource.buttonSelectTooltip, 
			icon: selectIcon
		};
		var menu = { items: [] };
		for(let refNode of listObjectRefNode) {
			menu.items.push( {
				title: refNode.selectInstanceFormTitle,
				method: () => { holder.selectInstance(refNode) }
			} )
		}
		return (
			<PopupMenu holder={holder} style={style} button={button} menu={menu} />
		);
		*/
	}
}

export default FieldList_Reference;
