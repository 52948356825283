import React from 'react';

import FieldString from '../../../components/plain/FieldString';
import FieldEnum from '../../../components/plain/FieldEnum';
import FieldReference from '../../../components/plain/FieldReference';
import FieldList_TableGroup from '../../../components/plain/FieldList_TableGroup';

import { BOOK_PATH } from '../../../util/routes';
import { FORM_BOOK_TABLE } from '../../../util/consts/forms';

const table = {
	columns: [
		{ columnId: 'docLinkType', title: 'Тип зв\’язку', tdHeaderStyle: {width: '20%'}},
		{ columnId: 'docLinkValue', title: 'Посилання', tdHeaderStyle: {width: '60%'}},
		{ columnId: 'docLinkIssue', title: 'Випуск', tdHeaderStyle: {width: '20%'}},
	]
}

const refNodes = [
	{
		classId: 'Book', plainFormPath: BOOK_PATH, 
		selectInstanceFormId: FORM_BOOK_TABLE,
		selectInstanceFormTitle: 'Книги'
	}
]

class TabBrDocLinks extends React.Component {
	
	constructor(props) {
		super(props);
	}
	
	render() {
		let plain = this.props.plain;
		let instance = this.props.instance;
		let parentField = this.props.parentField;
		if( ! parentField ) {
			parentField = instance.rootField;
		}
		return (
			<div style={{padding: '20px 20px 20px 20px' }}>
				<FieldList_TableGroup instance={instance} path={'docLink'} parentField={parentField} 
					plain={plain} plainDirtyCounter={plain.state.plainDirtyCounter}
					table={table} cell={Cell} ignoreTitle={true}
				/>
			</div>
		);
	}
}

class Cell extends React.Component {

	/*
		plainDirtyCounter
		instance
		columnId
		rowIndex
		plain
		fieldGroup
	*/
	constructor(props) {
		super(props);
	}
	
	render() {
		var columnId = this.props.columnId;
		var fieldGroup = this.props.fieldGroup;
		var plain = this.props.plain;

		if( columnId == 'docLinkType' ) {
			return (
				<FieldEnum instance={this.props.instance} path={'docLinkType'} plain={plain} parentField={fieldGroup} 
					ignoreTitle={true} styleInput={{width: '95%'}} 
				/>
			);
		}
		if( columnId == 'docLinkValue' ) {
			return ( 
				<FieldReference instance={this.props.instance} path={'docLinkValue'} plain={plain} 
					parentField={fieldGroup} refNodes={refNodes} 
					ignoreTitle={true} styles={{divMain: {width: '95%'}}} 
				/>
			);
		}
		if( columnId == 'docLinkIssue' ) {
			return ( 
				<FieldString instance={this.props.instance} path={'docLinkIssue'} plain={plain} parentField={fieldGroup} 
					ignoreTitle={true} styleInput={{width: '95%'}} 
				/>
			);
		}
		return null;
	}

}

export default TabBrDocLinks;