import React from 'react';
import { Link } from 'react-router-dom';
import config from '../../config/config';
import Field from './Field';
import SystemData from './SystemData';
import ButtonIconText from '../button/ButtonIconText';
import ButtonSelectInstance from '../../modal/selectinstance/ButtonSelectInstance';
import styles from './FullDescription.module.css';
import { addFieldToList, findFieldByPath } from './util';
import { getCurrentResource, getDefinedValue, getInstanceField, getInstanceFieldValue, setInstanceFieldValue } from '../../util/tools';
import { getUserInfo, getButtonMode } from '../../util/localStorage';
import { performApiRequest, ENTITY_LOAD_API_URL, MASTER_LOAD_REF_API_URL } from '../../util/restapi';

import saveIcon from '../../assets/icons/png24x24/Save.png';
import collapseIcon from '../../assets/icons/png24x24/collapse_silver.png';
import expandIcon from '../../assets/icons/png24x24/expand.png';
import deleteIcon from '../../assets/icons/png24x24/Delete.png';
import selectIcon from '../../assets/icons/png24x24/Search.png';
import insertIcon from '../../assets/icons/png24x24/insert.png';
import linkIcon from '../../assets/icons/png24x24/link.png';
import clearIcon from '../../assets/icons/png24x24/edit-clear.png';
import goIcon from '../../assets/icons/png24x24/go-jump.png';

class FieldGroup extends React.Component {

	constructor(props) {
		super(props);
		var fieldComponent = {
			field: this,
			path: props.field.path
		}
		this.resource = getCurrentResource().components.fulldescription.FieldGroup;
		props.plain.listFieldComponent.push(fieldComponent);
		if( props.parent && props.parent.field.listSubfields ) {
			addFieldToList( props.parent.field.listSubfields, fieldComponent );
		}
		if( props.field.model.id == 'root' )
			var collapsed = false;
		else
			collapsed = ! props.field.model.autoExpand;
		this.state = {
			isCollapsed: collapsed,
			inst: props.plain.instance
		};
		this.useIcons = getButtonMode() == 'icon';
		this.listSubfields = [];
		this.selectInstanceRvContext = {
			rateView: null,
			form: null,
			formTitle: null, 
			data: null
		};
		this.borrowUrl = '';
		if( props.field.model.listObjectRefNode.length > 0 ) {
			var refNode = props.field.model.listObjectRefNode[0];
			this.selectInstanceRvContext.form = refNode.selectInstanceFormId;
			this.selectInstanceRvContext.formTitle = refNode.selectInstanceFormTitle;
			this.borrowUrl = refNode.borrowUrl;
		}
		if(props.plain.model ) {
			var fieldOverrideModel = findFieldByPath( props.plain.model.fields, props.field.path );
			if( fieldOverrideModel && fieldOverrideModel.selectInstanceRvContext) {
				for(let key of Object.keys( fieldOverrideModel.selectInstanceRvContext) ) {
					this.selectInstanceRvContext[key] = fieldOverrideModel.selectInstanceRvContext[key];
				}
			}
		}
	}
	
	expand = () => {
		this.setState( { isCollapsed: false } );
	}
	collapse = () => {
		this.setState( { isCollapsed: true } );
	}
	appendSubfields = (fieldModel, path, subfields) => {
		for(let sf of fieldModel.subfields) {
			var newPath = [...path];
			newPath.push( sf.id );
			if( !sf.multiple && sf.type == 'group' && sf.skipNode) {
				this.appendSubfields( sf, newPath, subfields );
			} else {
				var sfField = {
					model: sf,
					path: newPath
				};
				subfields.push( sfField );
			}
		}
	}
	goToRef = () => {
		var fieldGroup = getInstanceField(this.props.plain.instance, this.props.field.path);
		var fieldModel = this.props.field.model; 
		var objectRefNode = null;
		if( fieldModel.listObjectRefNode != null ) {
			for(let item of fieldModel.listObjectRefNode) {
				if( item.classId == '*' || item.classId == fieldGroup.className ) {
					objectRefNode = item;
					break;
				}
			}
		}
		var url = `${config.PATH}/` + objectRefNode.plainFormId + '/' + fieldGroup.id;
		
	}
	onInstanceSelected = async (ids) => {
		if( ids.length == 0 )
			return;
		if( this.borrowUrl ) {
			this.props.plain.component.borrowFields(this.borrowUrl, ids[0], '');
			return;
		}
		var request = {id: ids[0]};
		var response = await performApiRequest( ENTITY_LOAD_API_URL, request );
		if( response.success ) {
			var instance = response.data;
			setInstanceFieldValue( this.props.plain.instance, this.props.field.path, instance );
			this.setState({inst: this.props.plain.instance});
		} else {
			this.props.plain.app.showResponse( response );
		}
	}
	onLinkInstanceSelected = async (ids) => {
		if( ids.length == 0 )
			return;
		var request = {id: ids[0]};
		var response = await performApiRequest( MASTER_LOAD_REF_API_URL, request );
		if( response.success ) {
			var ref = response.data;
			var fieldGroup = getInstanceField(this.props.plain.instance, this.props.field.path);
			fieldGroup.className = ref.className;
			fieldGroup.id = ref.id;
			fieldGroup.globalId = ref.globalId;
			fieldGroup.cardMaster = ref.card;
			fieldGroup.card = ref.card;
			this.setState({inst: this.props.plain.instance});
		} else {
			this.props.plain.app.showResponse( response );
		}
	}
	clearLink = () => {
		var fieldGroup = getInstanceField(this.props.plain.instance, this.props.field.path);
		if( fieldGroup ) {
			fieldGroup.className = null;
			fieldGroup.id = null;
			fieldGroup.globalId = null;
			fieldGroup.cardMaster = null;
			fieldGroup.card = null;
			this.setState({inst: this.props.plain.instance});
		}
	}
	clearInstance = () => {
		setInstanceFieldValue(this.props.plain.instance, this.props.field.path, {});
		this.setState({inst: this.props.plain.instance});
	}
	
	render() {
		if( this.props.field.model.ignoreHeader ) {
			for(let sf of this.props.field.model.subfields) {
				if( sf.multiple ) {
					var field = getInstanceField(this.props.plain.instance, this.props.field.path);
					if( ! field[ sf.id ] )
						field[ sf.id ] = [];
				} else if (sf.type == 'group') {
					var field = getInstanceField(this.props.plain.instance, this.props.field.path);
					if( ! field[ sf.id ] )
						field[ sf.id ] = {};
				}
			}
			var arraySubfield = [];
			this.appendSubfields( this.props.field.model, this.props.field.path, arraySubfield );
		
			const subfieldsHtml = arraySubfield.map((sf, index) => {
				if( index == 0 )
					return (
						<FirstRow key={index} holder={this} plain={this.props.plain} field={sf} />
					);
				else
					return (
						<Field key={index} plain={this.props.plain} field={sf} /> 
					)
			});
			return (
				<div className={styles.divFieldGroup}>
					<div className={styles.divSubfields}>
						{subfieldsHtml}
					</div>
				</div>
			);
		}

		if( this.state.isCollapsed ) {
			return (
				<div className={styles.divFieldGroup}>
					<HeaderRow holder={this} />
				</div>
			);
		} else {
			for(let sf of this.props.field.model.subfields) {
				if( sf.multiple ) {
					var field = getInstanceField(this.props.plain.instance, this.props.field.path);
					if( ! field[ sf.id ] )
						field[ sf.id ] = [];
				} else if (sf.type == 'group') {
					var field = getInstanceField(this.props.plain.instance, this.props.field.path);
					if( ! field[ sf.id ] )
						field[ sf.id ] = {};
				}
			}

			var arraySubfield = [];
			this.appendSubfields( this.props.field.model, this.props.field.path, arraySubfield );
		
			const subfields = arraySubfield.map((sf, index) => {
				return (
					<Field key={index} plain={this.props.plain} field={sf} /> 
				)
			});

			return (
				<div className={styles.divFieldGroup}>
					<HeaderRow holder={this} />
					<div className={styles.divSubfields}>
						<GroupSystemData holder={this} />
						{subfields}
					</div>
				</div>
			);
		}
	}	
}

function HeaderRow(props) {
	var holder = props.holder;
	var resource = holder.resource;
	var fieldModel = holder.props.field.model; 
	if( fieldModel.id == 'root' )
		return (<RootHeaderRow holder={holder} card={props.card} />);

	var fieldGroup = getInstanceField(holder.props.plain.instance, holder.props.field.path);
	if( fieldGroup && fieldGroup.card ) {
		var objectRefNode = null;
		if( fieldModel.listObjectRefNode != null ) {
			for(let item of fieldModel.listObjectRefNode) {
				if( item.classId == '*' || item.classId == fieldGroup.className ) {
					objectRefNode = item;
					break;
				}
			}
		}
		if( objectRefNode == null || ! fieldGroup.id ) {
			var cardHtml = ( <span>{fieldGroup.card}</span> );
		} else {
			var url = `${config.PATH}/` + objectRefNode.plainFormId + '/' + fieldGroup.id;
			//<a href={url}>{fieldGroup.card}</a>
			var cardHtml = ( 
				<div style={{display: 'flex', alignItems: 'center'}}>
					<input type='text' readonly={true} value={fieldGroup.card} 
						style={{width: '95%', height: '25px', marginRight: '5px', fontWeight: 'bold'}} />
					<button type='button' onClick={holder.goToRef} title={resource.buttonGoToRefTooltip}>
						<Link to={url} target='_blank'><img src={goIcon} /></Link>
					</button>
				</div> 
			);
		}
	} else
		var cardHtml = null;

	var listItem = holder.props.listItem; 
	if( listItem ) {
		var indexName = ' [' + (listItem.itemIndex + 1) + ']';
		var listItemTools = listItem.tools;
	} else {
		var indexName = '';
		var listItemTools = ( null );
	}

	if( holder.state.isCollapsed ) {
		var buttonExpandOrCollapse = (
			<ButtonIconText 
				useIcon={holder.useIcons} onClick={holder.expand} styles={styles}
				tooltip={resource.buttonExpandTooltip} text={resource.buttonExpand}
				icon={expandIcon}
			/>
		);
	} else {
		var buttonExpandOrCollapse = (
			<ButtonIconText 
				useIcon={holder.useIcons} onClick={holder.collapse} styles={styles}
				tooltip={resource.buttonCollapseTooltip} text={resource.buttonCollapse}
				icon={collapseIcon}
			/>
		);
	}
	if( holder.state.isCollapsed && fieldModel.shortFormField ) {
		var newPath = [...holder.props.field.path];
		newPath.push( fieldModel.shortFormField.id );
		var sfField = {
			model: fieldModel.shortFormField,
			path: newPath
		};
		var mainHtml = <Field plain={holder.props.plain} field={sfField} />;
	} else {
		var mainHtml = cardHtml;
	}

	var selectObjectButton = null;
	var linkObjectButton = null;
	var clearObjectButton = null;
	if( fieldModel.listObjectRefNode.length > 0 ) {
		selectObjectButton = (
			<ButtonSelectInstance
				text={resource.buttonSelect} tooltip={resource.buttonSelectTooltip} icon={insertIcon}
				selectInstanceRvContext={holder.selectInstanceRvContext} 
				onInstanceSelected={holder.onInstanceSelected}
			/>
		);
		linkObjectButton = (
			<ButtonSelectInstance
				text={resource.buttonSelect} tooltip={resource.buttonLinkTooltip} icon={linkIcon}
				selectInstanceRvContext={holder.selectInstanceRvContext} 
				onInstanceSelected={holder.onLinkInstanceSelected}
			/>
		);
		clearObjectButton = (
			<ButtonIconText 
				useIcon={holder.useIcons}
				style={{marginLeft: '5px'}} 
				onClick={holder.clearInstance}
				styles={styles}
				tooltip={resource.buttonClearTooltip}
				text={resource.buttonClear}
				icon={clearIcon}
			/>
		);
	}
	
	return (
		<div style={{display: 'flex', alignItems: 'center'}}>
			<div style={{flexGrow: 1}}>
				{buttonExpandOrCollapse}
			</div>
			<Label model={fieldModel} indexName={indexName} />
			<div style={{flexGrow: 100, marginLeft: '10px'}}>
				{mainHtml}
			</div>
			<div style={{flexGrow: 0}}>
				{selectObjectButton}
			</div>
			<div style={{flexGrow: 0, marginLeft: '5px'}}>
				{linkObjectButton}
			</div>
			<div style={{flexGrow: 0, marginLeft: '5px'}}>
				{clearObjectButton}
			</div>
			<div style={{flexGrow: 0}}>
				{listItemTools}
			</div>
		</div>
	);
}

function FirstRow(props) {
// holder, plain, field
	var fieldModel = props.holder.props.field.model;
	var listItem = props.holder.props.listItem; 
	if( listItem ) {
		var indexName = ' [' + (listItem.itemIndex + 1) + ']';
		var listItemTools = listItem.tools;
	} else {
		var indexName = '';
		var listItemTools = ( null );
	}
	var selectObjectButton = null;
//			<div style={{flexGrow: 0, marginRight: '10px'}}>{indexName}</div>
	return (
		<div key={0} style={{display: 'flex', alignItems: 'center'}}>
			<Label model={fieldModel} indexName={indexName} />
			<div style={{flexGrow: 100}}>
				<Field plain={props.plain} field={props.field} /> 		
			</div>
			<div style={{flexGrow: 0}}>
				{selectObjectButton}
			</div>
			<div style={{flexGrow: 0}}>
				{listItemTools}
			</div>
		</div>
	);
}

function RootHeaderRow(props) {
	var holder = props.holder;
	return (
		<div style={{display: 'flex'}}>
			<div style={{flexGrow: 0, marginRight: '7px'}}>
				<ButtonIconText 
					useIcon={holder.useIcons} 
					onClick={holder.props.plain.saveEntity}
					styles={styles}
					tooltip={holder.resource.buttonSaveTooltip}
					text={holder.resource.buttonSave}
					icon={saveIcon}
				/>
			</div>
			<div style={{flexGrow: 100}}>
				{props.card}
			</div>
		</div>
	);
}

function Label(props) {
	var fieldModel = props.model; 
	if( props.model.ignoreLabel && props.model.ignoreLabelIndex )
		return null;

	var labelText = '';
	var indexName = props.indexName;
	if( ! fieldModel.ignoreLabel)
		labelText += fieldModel.title;  
	if( indexName && ! fieldModel.ignoreLabelIndex) {
		labelText += indexName;
	}
	
	var tooltip = props.model.description;
	if( tooltip )
		return (
			<div className={styles.divGroupLabelWithTooltip} style={{flexGrow: 1, marginLeft: '7px'}}>
				{labelText} 
				<span className={styles.tooltiptext}>{tooltip}</span>
			</div>
		);
	else {
		return (
			<div style={{flexGrow: 1, marginLeft: '7px'}}>
				{labelText} 
			</div>
		);
	}
}

function GroupSystemData(props) {
	var isRoot = (props.holder.props.field.model.id == 'root');
	if( isRoot && props.holder.props.plain.instance.id ) {
		var userInfo = getUserInfo();
		if( userInfo.permission.superuser ) {
			return (<SystemData plain={props.holder.props.plain}/>);
		} else {
			return (null);
		}
	} else
		return (null);
}

export default FieldGroup;
