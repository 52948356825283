import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './NavBar.module.css';

import { getCurrentResource } from '../../util/tools';
import {getUserInfo, canViewForm} from '../../util/localStorage';

import {
	FORM_AUTHORITY_AUTHOR_TABLE,
	FORM_AUTHORITY_PUBLISHER_TABLE,
	FORM_BOOK_TABLE,
	FORM_CONFIRMATION_TABLE,
	FORM_CONTACT_TABLE,
	FORM_DERIVATIVE_TABLE,
	FORM_DIGITAL_STORE_TABLE,
	FORM_EBOOK_BANK_TABLE,
	FORM_EBOOK_REPORT_TABLE,
	FORM_EBOOK_REPORT_ITEM_TABLE,
	FORM_EBOOK_SUPPLIER_TABLE,
	FORM_EBOOK_TERMINAL_TABLE,
	FORM_ESTIMATION_TABLE,
	FORM_EVENT_TABLE,
	FORM_HONOR_TABLE,
	FORM_LIBRARY_TABLE,
	FORM_LOCATION_TABLE,
	FORM_MESSAGE_TABLE,
	FORM_OFFER_TABLE,
	FORM_ORDER_TABLE,
	FORM_ORGANIZATION_TABLE,
	FORM_PROCUREMENT_TABLE,
	FORM_PRODUCT_TABLE,
	FORM_PROVIDER_TABLE,
	FORM_TOPIC_TABLE,
	FORM_USER_GROUP_TABLE,
	FORM_USER_TABLE,

	FORM_DBMANAGER
} from '../../util/consts/forms';

import {
	HELLO_PATH,
	DBMANAGER_PATH,
	SYSINFO_PATH,
	
	AUTHORITY_AUTHOR_TABLE_PATH,
	AUTHORITY_PUBLISHER_TABLE_PATH,
	BOOK_TABLE_PATH,
	CONFIRMATION_TABLE_PATH,
	CONTACT_TABLE_PATH,
	DERIVATIVE_TABLE_PATH,
	DIGITAL_STORE_TABLE_PATH,
	EBOOK_BANK_TABLE_PATH,
	EBOOK_REPORT_TABLE_PATH,
	EBOOK_REPORT_ITEM_TABLE_PATH,
	EBOOK_SUPPLIER_TABLE_PATH,
	EBOOK_TERMINAL_TABLE_PATH,
	ESTIMATION_TABLE_PATH,
	EVENT_TABLE_PATH,
	HONOR_TABLE_PATH,
	LIBRARY_TABLE_PATH,
	LOCATION_TABLE_PATH,
	MESSAGE_TABLE_PATH,
	OFFER_TABLE_PATH,
	ORDER_TABLE_PATH,
	ORGANIZATION_TABLE_PATH,
	PROCUREMENT_TABLE_PATH,
	PRODUCT_TABLE_PATH,
	PROVIDER_TABLE_PATH,
	TOPIC_TABLE_PATH,
	USER_GROUP_TABLE_PATH,
	USER_TABLE_PATH
} from '../../util/routes';


class NavBar extends Component {
	
	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.navigation.NavBar;
		this.userInfo = getUserInfo(); 
		this.state = {
			userInfo: this.userInfo
		}
		this.menuCatalog = {
			title: this.resource.menuCatalog,
			items: [
				{path: AUTHORITY_AUTHOR_TABLE_PATH, form: FORM_AUTHORITY_AUTHOR_TABLE, title: this.resource.tfAuthorityAuthorTitle},
				{path: AUTHORITY_PUBLISHER_TABLE_PATH, form: FORM_AUTHORITY_PUBLISHER_TABLE, title: this.resource.tfAuthorityPublisherTitle},
				{path: TOPIC_TABLE_PATH, form: FORM_TOPIC_TABLE, title: this.resource.tfTopicTitle},
				{path: BOOK_TABLE_PATH, form: FORM_BOOK_TABLE, title: this.resource.tfBookTitle},
				{path: DERIVATIVE_TABLE_PATH, form: FORM_DERIVATIVE_TABLE, title: this.resource.tfDerivativeTitle},
				{path: HONOR_TABLE_PATH, form: FORM_HONOR_TABLE, title: this.resource.tfHonorTitle},
			]
		};
		this.menuProducts = {
			title: this.resource.menuProducts,
			items: [
				{path: MESSAGE_TABLE_PATH, form: FORM_MESSAGE_TABLE, title: this.resource.tfMessageTitle},
				{path: PRODUCT_TABLE_PATH, form: FORM_PRODUCT_TABLE, title: this.resource.tfProductTitle},
				{path: CONFIRMATION_TABLE_PATH, form: FORM_CONFIRMATION_TABLE, title: this.resource.tfConfirmationTitle},
				{path: OFFER_TABLE_PATH, form: FORM_OFFER_TABLE, title: this.resource.tfOfferTitle},
				{path: ORDER_TABLE_PATH, form: FORM_ORDER_TABLE, title: this.resource.tfOrderTitle},
				{path: PROCUREMENT_TABLE_PATH, form: FORM_PROCUREMENT_TABLE, title: this.resource.tfProcurementTitle},
				{path: ESTIMATION_TABLE_PATH, form: FORM_ESTIMATION_TABLE, title: this.resource.tfEstimationTitle},
			]
		};
		this.menuSubjects = {
			title: this.resource.menuSubjects,
			items: [
				{path: PROVIDER_TABLE_PATH, form: FORM_PROVIDER_TABLE, title: this.resource.tfProviderTitle},
				{path: LIBRARY_TABLE_PATH, form: FORM_LIBRARY_TABLE, title: this.resource.tfLibraryTitle},
				{path: ORGANIZATION_TABLE_PATH, form: FORM_ORGANIZATION_TABLE, title: this.resource.tfOrganizationTitle},
				{path: CONTACT_TABLE_PATH, form: FORM_CONTACT_TABLE, title: this.resource.tfContactTitle},
				{path: EVENT_TABLE_PATH, form: FORM_EVENT_TABLE, title: this.resource.tfEventTitle},
				{path: LOCATION_TABLE_PATH, form: FORM_LOCATION_TABLE, title: this.resource.tfLocationTitle},
			]
		};
		this.menuEBook = {
			title: this.resource.menuEBook,
			items: [
				{path: EBOOK_BANK_TABLE_PATH, form: FORM_EBOOK_BANK_TABLE, title: this.resource.tfEBookBankTitle},
				{path: EBOOK_REPORT_TABLE_PATH, form: FORM_EBOOK_REPORT_TABLE, title: this.resource.tfEBookReportTitle},
				{path: EBOOK_REPORT_ITEM_TABLE_PATH, form: FORM_EBOOK_REPORT_ITEM_TABLE, title: this.resource.tfEBookReportItemTitle},
				{path: EBOOK_SUPPLIER_TABLE_PATH, form: FORM_EBOOK_SUPPLIER_TABLE, title: this.resource.tfEBookSupplierTitle},
				{path: EBOOK_TERMINAL_TABLE_PATH, form: FORM_EBOOK_TERMINAL_TABLE, title: this.resource.tfEBookTerminalTitle},
			]
		};
		this.menuAdmin = {
			title: this.resource.menuAdmin,
			items: [
				{path: USER_GROUP_TABLE_PATH, form: FORM_USER_GROUP_TABLE, title: this.resource.tfUserGroupTitle},
				{path: USER_TABLE_PATH, form: FORM_USER_TABLE, title: this.resource.tfUserTitle},
				{path: DIGITAL_STORE_TABLE_PATH, form: FORM_DIGITAL_STORE_TABLE, title: this.resource.tfDigitalStoreTitle},
				{path: DBMANAGER_PATH, form: FORM_DBMANAGER, title: this.resource.formDbmanagerTitle},
				{path: SYSINFO_PATH, title: this.resource.formSystemInfoTitle},
			]
		};
	}
	
	render() {
		return (
			<div className={styles.divMenuBar}>
				<div className={styles.divMenu}>
					<a className={styles.aLink} href={HELLO_PATH}>{this.resource.formMainTitle}</a>
				</div>
				<Menu holder={this} menu={this.menuCatalog} />
				<Menu holder={this} menu={this.menuProducts} />
				<Menu holder={this} menu={this.menuSubjects} />
				<Menu holder={this} menu={this.menuEBook} />
				<Menu holder={this} menu={this.menuAdmin} />
			</div>
		);
		/*
		if( this.userInfo.login == 'admin' ) {
			return (
				<React.Fragment>
					<div className={styles.divNavBar}>
	
						<div className={styles.divLinkRow}>
							<Link path={HELLO_PATH} title={this.resource.formMainTitle} />
							<Link path={AUTHORITY_AUTHOR_TABLE_PATH} form={FORM_AUTHORITY_AUTHOR_TABLE} userInfo={this.state.userInfo} title={this.resource.tfAuthorityAuthorTitle} />
							<Link path={AUTHORITY_PUBLISHER_TABLE_PATH} form={FORM_AUTHORITY_PUBLISHER_TABLE} userInfo={this.state.userInfo} title={this.resource.tfAuthorityPublisherTitle} />
							<Link path={TOPIC_TABLE_PATH} form={FORM_TOPIC_TABLE} userInfo={this.state.userInfo} title={this.resource.tfTopicTitle} />
							<Link path={BOOK_TABLE_PATH} title={this.resource.tfBookTitle} />
							<Link path={DERIVATIVE_TABLE_PATH} form={FORM_DERIVATIVE_TABLE} userInfo={this.state.userInfo} title={this.resource.tfDerivativeTitle} />
							<Link path={HONOR_TABLE_PATH} form={FORM_HONOR_TABLE} userInfo={this.state.userInfo} title={this.resource.tfHonorTitle} />
						</div>
	
						<div className={styles.divLinkRow}>
							<Link path={PRODUCT_TABLE_PATH} form={FORM_PRODUCT_TABLE} userInfo={this.state.userInfo} title={this.resource.tfProductTitle} />
							<Link path={CONFIRMATION_TABLE_PATH} form={FORM_CONFIRMATION_TABLE} userInfo={this.state.userInfo} title={this.resource.tfConfirmationTitle} />
							<Link path={OFFER_TABLE_PATH} form={FORM_OFFER_TABLE} userInfo={this.state.userInfo} title={this.resource.tfOfferTitle} />
							<Link path={ORDER_TABLE_PATH} form={FORM_ORDER_TABLE} userInfo={this.state.userInfo} title={this.resource.tfOrderTitle} />
							<Link path={PROCUREMENT_TABLE_PATH} form={FORM_PROCUREMENT_TABLE} userInfo={this.state.userInfo} title={this.resource.tfProcurementTitle} />
							<Link path={ESTIMATION_TABLE_PATH} form={FORM_ESTIMATION_TABLE} userInfo={this.state.userInfo} title={this.resource.tfEstimationTitle} />
						</div>
	
						<div className={styles.divLinkRow}>
							<Link path={MESSAGE_TABLE_PATH} form={FORM_MESSAGE_TABLE} userInfo={this.state.userInfo} title={this.resource.tfMessageTitle} />
							<Link path={PROVIDER_TABLE_PATH} form={FORM_PROVIDER_TABLE} userInfo={this.state.userInfo} title={this.resource.tfProviderTitle} />
							<Link path={LIBRARY_TABLE_PATH} form={FORM_LIBRARY_TABLE} userInfo={this.state.userInfo} title={this.resource.tfLibraryTitle} />
							<Link path={ORGANIZATION_TABLE_PATH} form={FORM_ORGANIZATION_TABLE} userInfo={this.state.userInfo} title={this.resource.tfOrganizationTitle} />
							<Link path={CONTACT_TABLE_PATH} form={FORM_CONTACT_TABLE} userInfo={this.state.userInfo} title={this.resource.tfContactTitle} />
							<Link path={EVENT_TABLE_PATH} form={FORM_EVENT_TABLE} userInfo={this.state.userInfo} title={this.resource.tfEventTitle} />
							<Link path={LOCATION_TABLE_PATH} form={FORM_LOCATION_TABLE} userInfo={this.state.userInfo} title={this.resource.tfLocationTitle} />
							<Link path={DIGITAL_STORE_TABLE_PATH} form={FORM_DIGITAL_STORE_TABLE} userInfo={this.state.userInfo} title={this.resource.tfDigitalStoreTitle} />
						</div>
	
						<DivEBook holder={this} />
	
						<div className={styles.divLinkRow}>
							<Link path={USER_GROUP_TABLE_PATH} form={FORM_USER_GROUP_TABLE} userInfo={this.state.userInfo} title={this.resource.tfUserGroupTitle} />
							<Link path={USER_TABLE_PATH} form={FORM_USER_TABLE} userInfo={this.state.userInfo} title={this.resource.tfUserTitle} />
							<Link path={DBMANAGER_PATH} form={FORM_DBMANAGER} userInfo={this.state.userInfo} title={this.resource.formDbmanagerTitle} />
						</div>
					</div>
				</React.Fragment>
			);
		} else {
			return (
				<div className={styles.divNavBar}>
					<Link path={HELLO_PATH} title={this.resource.formMainTitle} />
					<Link path={AUTHORITY_AUTHOR_TABLE_PATH} form={FORM_AUTHORITY_AUTHOR_TABLE} userInfo={this.state.userInfo} title={this.resource.tfAuthorityAuthorTitle} />
					<Link path={AUTHORITY_PUBLISHER_TABLE_PATH} form={FORM_AUTHORITY_PUBLISHER_TABLE} userInfo={this.state.userInfo} title={this.resource.tfAuthorityPublisherTitle} />
					<Link path={TOPIC_TABLE_PATH} form={FORM_TOPIC_TABLE} userInfo={this.state.userInfo} title={this.resource.tfTopicTitle} />
					<Link path={BOOK_TABLE_PATH} form={FORM_BOOK_TABLE} userInfo={this.state.userInfo}  title={this.resource.tfBookTitle} />
					<Link path={DERIVATIVE_TABLE_PATH} form={FORM_DERIVATIVE_TABLE} userInfo={this.state.userInfo} title={this.resource.tfDerivativeTitle} />
					<Link path={HONOR_TABLE_PATH} form={FORM_HONOR_TABLE} userInfo={this.state.userInfo} title={this.resource.tfHonorTitle} />
					<Link path={PRODUCT_TABLE_PATH} form={FORM_PRODUCT_TABLE} userInfo={this.state.userInfo} title={this.resource.tfProductTitle} />
					<Link path={CONFIRMATION_TABLE_PATH} form={FORM_CONFIRMATION_TABLE} userInfo={this.state.userInfo} title={this.resource.tfConfirmationTitle} />
					<Link path={OFFER_TABLE_PATH} form={FORM_OFFER_TABLE} userInfo={this.state.userInfo} title={this.resource.tfOfferTitle} />
					<Link path={ORDER_TABLE_PATH} form={FORM_ORDER_TABLE} userInfo={this.state.userInfo} title={this.resource.tfOrderTitle} />
					<Link path={PROCUREMENT_TABLE_PATH} form={FORM_PROCUREMENT_TABLE} userInfo={this.state.userInfo} title={this.resource.tfProcurementTitle} />
					<Link path={ESTIMATION_TABLE_PATH} form={FORM_ESTIMATION_TABLE} userInfo={this.state.userInfo} title={this.resource.tfEstimationTitle} />
					<Link path={MESSAGE_TABLE_PATH} form={FORM_MESSAGE_TABLE} userInfo={this.state.userInfo} title={this.resource.tfMessageTitle} />
					<Link path={PROVIDER_TABLE_PATH} form={FORM_PROVIDER_TABLE} userInfo={this.state.userInfo} title={this.resource.tfProviderTitle} />
					<Link path={LIBRARY_TABLE_PATH} form={FORM_LIBRARY_TABLE} userInfo={this.state.userInfo} title={this.resource.tfLibraryTitle} />
					<Link path={ORGANIZATION_TABLE_PATH} form={FORM_ORGANIZATION_TABLE} userInfo={this.state.userInfo} title={this.resource.tfOrganizationTitle} />
					<Link path={CONTACT_TABLE_PATH} form={FORM_CONTACT_TABLE} userInfo={this.state.userInfo} title={this.resource.tfContactTitle} />
					<Link path={EVENT_TABLE_PATH} form={FORM_EVENT_TABLE} userInfo={this.state.userInfo} title={this.resource.tfEventTitle} />
					<Link path={LOCATION_TABLE_PATH} form={FORM_LOCATION_TABLE} userInfo={this.state.userInfo} title={this.resource.tfLocationTitle} />
					<Link path={DIGITAL_STORE_TABLE_PATH} form={FORM_DIGITAL_STORE_TABLE} userInfo={this.state.userInfo} title={this.resource.tfDigitalStoreTitle} />
					<Link path={EBOOK_BANK_TABLE_PATH} form={FORM_EBOOK_BANK_TABLE} userInfo={this.state.userInfo} title={this.resource.tfEBookBankTitle} />
					<Link path={EBOOK_REPORT_TABLE_PATH} form={FORM_EBOOK_REPORT_TABLE} userInfo={this.state.userInfo} title={this.resource.tfEBookReportTitle} />
					<Link path={EBOOK_REPORT_ITEM_TABLE_PATH} form={FORM_EBOOK_REPORT_ITEM_TABLE} userInfo={this.state.userInfo} title={this.resource.tfEBookReportItemTitle} />
					<Link path={EBOOK_SUPPLIER_TABLE_PATH} form={FORM_EBOOK_SUPPLIER_TABLE} userInfo={this.state.userInfo} title={this.resource.tfEBookSupplierTitle} />
					<Link path={EBOOK_TERMINAL_TABLE_PATH} form={FORM_EBOOK_TERMINAL_TABLE} userInfo={this.state.userInfo} title={this.resource.tfEBookTerminalTitle} />
					<Link path={USER_GROUP_TABLE_PATH} form={FORM_USER_GROUP_TABLE} userInfo={this.state.userInfo} title={this.resource.tfUserGroupTitle} />
					<Link path={USER_TABLE_PATH} form={FORM_USER_TABLE} userInfo={this.state.userInfo} title={this.resource.tfUserTitle} />
				</div>
			);
		}
		*/
	}
}

function Link(props) {
	const { form, path, userInfo, title } = props;
	if( userInfo == null || canViewForm(form, userInfo) )
		return <NavLink to={path} className={styles.navLinkPassive} activeClassName={styles.navLinkActive}>{title}</NavLink>
	else
		return null;
}

function DivEBook(props) {
	var res = props.holder.resource;
	var userInfo = props.holder.state.userInfo;
	return (
		<div className={styles.divLinkRow}>
			<Link path={EBOOK_BANK_TABLE_PATH} form={FORM_EBOOK_BANK_TABLE} userInfo={userInfo} title={res.tfEBookBankTitle} />
			<Link path={EBOOK_REPORT_TABLE_PATH} form={FORM_EBOOK_REPORT_TABLE} userInfo={userInfo} title={res.tfEBookReportTitle} />
			<Link path={EBOOK_REPORT_ITEM_TABLE_PATH} form={FORM_EBOOK_REPORT_ITEM_TABLE} userInfo={userInfo} title={res.tfEBookReportItemTitle} />
			<Link path={EBOOK_SUPPLIER_TABLE_PATH} form={FORM_EBOOK_SUPPLIER_TABLE} userInfo={userInfo} title={res.tfEBookSupplierTitle} />
			<Link path={EBOOK_TERMINAL_TABLE_PATH} form={FORM_EBOOK_TERMINAL_TABLE} userInfo={userInfo} title={res.tfEBookTerminalTitle} />
		</div>
	);
}

class Menu extends React.Component  {
	
	constructor(props) {
		super(props);
	}
	
	render() {
		var res = this.props.holder.resource;
		var userInfo = this.props.holder.state.userInfo;
		var menu = this.props.menu;
		var activeMenu = [];
		for( let item of menu.items ) {
			if( userInfo == null || canViewForm(item.form, userInfo) ) {
				activeMenu.push( item );
			}
		}
		if( activeMenu.length == 0 )
			return null;
			
		var dropdownContentHtml = activeMenu.map( (item, index) => {
			return (
				<div key={index} className={styles.divDropdownItem}>
					<a href={item.path}>{item.title}</a>
				</div>
			);
		});
		
		return (
			<div className={styles.divDropdown}>
				<button className={styles.buttonDropdown}>{menu.title}</button>
				<div className={styles.divDropdownContent}>
					{dropdownContentHtml}
				</div>
			</div> 
		);
	}
}
export default NavBar;
