import React, { createRef } from 'react';

import Field from './Field';

import { IET_SET_VALUE, createEmptyFieldFileValue } from '../../util/realm/instance';
import { getDefinedItem } from '../../util/tools';
import { getLanguage, getUserInfo } from '../../util/localStorage';

import iconSelect from '../../assets/icons/png24x24/Search.png';
import iconDownload from '../../assets/icons/png24x24/Download.png';
import iconClear from '../../assets/icons/png24x24/Delete.png';

export const FILE_WAS_SET = "wasSet";
export const FILE_WAS_CLEARED = "wasCleared";

class FieldFile extends Field {

	fileRef = createRef();

	constructor(props) {
		super(props);
		this.state.value = getDefinedItem(this, ['field', 'value'], createEmptyFieldFileValue());
	}

	handleInstanceChange = (event) => {
		if( event.eventType == IET_SET_VALUE && event.field == this.field) {
			this.setState({ value: event.newValue });
		}
	}
	
	handleFileSelected = (e) => {
		let files = e.target.files;
		if( files && files.length > 0 ) {
			let instance = this.props.instance; 
			let field = this.field;
			let value = {...field.value};
			value.fileState = FILE_WAS_SET;
			value.fileBody = files[0];
			instance.setFieldValue( field, value );
		}
	}
	clearFile = () => {
		let value = createEmptyFieldFileValue();
		if( this.field.value != FILE_WAS_SET ) {
			value.fileState = FILE_WAS_CLEARED;
		}
		this.props.instance.setFieldValue( this.field, value );
	}
	downloadFile = async () => {
		let value = this.field.value;
		let xhr = new XMLHttpRequest();
		xhr.open('GET', value.fileURL, true);
		xhr.responseType = 'blob';
		xhr.setRequestHeader('Accept-Language', getLanguage() );
		let userInfo = getUserInfo();
		if( userInfo.jwt ) {
			xhr.setRequestHeader('Authorization', 'Bearer ' + userInfo.jwt );
		}
		xhr.onprogress = e => console.log(`${parseInt((e.loaded/e.total)*100)}%`);
		xhr.onload = async (e) => {
			if( xhr.status != 200 ) {
				const text = await xhr.response.text();
				this.props.plain.app.showMessage(text);
				console.log( text );
			} else {
				let blob = xhr.response;
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.style.display = 'none';
				a.href = url;
				a.download = value.sourceFileName;
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				console.log('your file has downloaded!');
			} 
		}
		xhr.send();
	}
	
	render() {
		
		if( this.field == null ) {
			return this.createDivUnknownField();
		}
		
		let value = this.state.value;
		
		let hasSavedFile = value.savedFileName ? true : false;
		let hasSelectedFile = value.fileState == FILE_WAS_SET;

		let buttonSelectFileDisabled = this.props.readOnly;
		let buttonDownloadFileDisabled = ! hasSavedFile || hasSelectedFile;
		let buttonClearFileDisabled = this.props.readOnly || ! hasSavedFile && ! hasSelectedFile;
					
		return (
			<div style={{display: 'flex', alignItems: 'center'}}>
				<div style={{flexGrow: '1'}}>
					{this.state.fileName}
				</div> 
				<input ref={this.fileRef} type={'file'} style={{display: 'none'}} onChange={e => this.handleFileSelected(e)} />
				<button style={{marginLeft: '10px'}}
					onClick={e => this.fileRef.current.click()}
					disabled={buttonSelectFileDisabled}
				>
					<img src={iconSelect} />
				</button>
				<button style={{marginLeft: '5px'}}
					onClick={this.downloadFile} 
					disabled={buttonDownloadFileDisabled}
				>
					<img src={iconDownload} />
				</button>
				<button style={{marginLeft: '5px'}}
					onClick={this.clearFile} 
					disabled={buttonClearFileDisabled}
				>
					<img src={iconClear} />
				</button>
			</div>
		);
	}
}

export default FieldFile;