import React, { Component } from 'react';
import Modal from 'react-responsive-modal';

import NavBar from '../../components/navigation/NavBar';
import RateView from '../../components/rateview/RateView';

import { getCurrentResource } from '../../util/tools';
import { canPerformAction } from '../../util/localStorage';
import { 
	performApiDownloadRequest, performApiRequestForm, 
	ACTION_BOOK_EXPORT_API_URL, ACTION_BOOK_IMPORT_API_URL 
} from '../../util/restapi';
import { ACTION_BOOK_EXPORT, ACTION_BOOK_IMPORT } from '../../util/consts/actions';
import { FORM_BOOK_TABLE } from '../../util/consts/forms';

import iconImport from '../../assets/icons/png24x24/get_message.png';
import iconExport from '../../assets/icons/png24x24/send_message.png';

const MODAL_MODE_ACTIVE = 'a';
const MODAL_MODE_WAIT = 'w';
const MODAL_MODE_COMPLETE = 'c';
const MODAL_MODE_ERROR = 'e';

class BookListPage extends Component {
	
	constructor(props) {
		super(props);
		this.refExportModal = React.createRef();
		this.refImportModal = React.createRef();
		this.state = {
			isLocalExportModalOpen: false,
			isLocalImportModalOpen: false,
		}
		
		this.resource = getCurrentResource().pages.tableform.BookList;
		var paramCacheId = props.match.params.param;

		this.rvContext = {
			form: FORM_BOOK_TABLE,
			data: null
		};
		this.rvOptions = {
			form: FORM_BOOK_TABLE,
			formTitle: this.resource.formTitle,
//			leftSideTools: this.leftSideTools(),
			controlPanelTools: this.controlPanelTools(),
			paramCacheId: paramCacheId,
			transition: null
		};
	}

	openExportModal = () => {
		if( ! this.rvContext.data )
			return;
		
		var listInstanceId = [];
		for( var row of this.rvContext.data ) {
			if( row.checked ) {
				listInstanceId.push( row.id );
			}
		}
		if( listInstanceId.length == 0 )
			return;
		
		this.refExportModal.current.initModal(listInstanceId);
		this.setState({ isLocalExportModalOpen: true});	
	}
	openImportModal = () => {
		this.refImportModal.current.initModal();
		this.setState({ isLocalImportModalOpen: true });	
	}
	closeExportModal = () => {
		this.setState({ isLocalExportModalOpen: false });	
	}
	closeImportModal = () => {
		this.setState({ isLocalImportModalOpen: false });	
	}
	
	leftSideTools = () => {
		return (
			<div style={{textAlign: "left"}}>
				<ButtonImport holder={this} />
				<ButtonExport holder={this} />
			</div>			
		);
	}
	controlPanelTools = () => {
		return (
			<div style={{textAlign: 'left', marginLeft: '20px'}}>
				<ControlPanelButtonExport holder={this} />
				<ControlPanelButtonImport holder={this} />
			</div>			
		);
	}

	render() {
		return (
			<React.Fragment>
				<NavBar app={this.props.app} />
				<RateView rvContext={this.rvContext} rvOptions={this.rvOptions} app={this.props.app} />

				<ExportModal ref={this.refExportModal} page={this} 
					isModalOpen={this.state.isLocalExportModalOpen} 
					close={this.closeExportModal} 
				/>
				<ImportModal ref={this.refImportModal} page={this} 
					isModalOpen={this.state.isLocalImportModalOpen} 
					close={this.closeImportModal} 
				/>
			</React.Fragment>
		)
	}
	
}

class ExportModal extends React.Component {

	constructor(props) {
		super(props);
		this.listInstanceId = [];
		this.errorMessage = '';
		this.errorLog = '';
		this.state = {
			modalMode: MODAL_MODE_ACTIVE,
			fileName: '',
			format: "ubr",
		};
	}
	initModal = (listInstanceId) => {
		this.listInstanceId = listInstanceId;
		this.errorMessage = '';
		this.errorLog = '';
		this.setState({
			modalMode: MODAL_MODE_ACTIVE,
			fileName: '',
			format: "ubr",
		});
	}

	handleInputEdit = (e) => {
		const { name, value } = e.target;
		if( name == 'filename' )
			this.setState({fileName: value});
	};
	handleFormatSelectClick = (e) => {
		this.setState({format: e.target.value});
	}
	startExport = async () => {
		this.setState({modalMode: MODAL_MODE_WAIT})
		
		var exportOptions = {
			debugMode: false,
			format: this.state.format,
			listInstanceId: this.listInstanceId
		};
		var response = await performApiDownloadRequest(
			ACTION_BOOK_EXPORT_API_URL, exportOptions, 'text/xml');
		if( response.success ) {
			var fileName = this.state.fileName;
			if( ! fileName )
				fileName = 'export_book.xml';
			const blobUrl = window.URL.createObjectURL(response.blob);
			const link = document.createElement('a');
			link.href = blobUrl;
			link.download = fileName;
			document.body.appendChild(link);
			link.click();
			this.setState({modalMode: MODAL_MODE_ACTIVE})
			this.props.page.closeExportModal();
		} else {
			this.errorMessage = response.message; 
			this.errorLog = response.log;
			this.setState({modalMode: MODAL_MODE_ERROR})
		}
	}
	tryAgain = () => {
		this.setState({
			modalMode: MODAL_MODE_ACTIVE,
		});
	}

	render() {
		var resource = this.props.page.resource;
		if( this.state.modalMode == MODAL_MODE_ERROR ) {
			var modalBodyHtml = (
				<React.Fragment>
					<div style={{color: 'red', textAlign: 'center', padding: '30px', fontSize: '16pt'}}>
						{this.errorMessage}
					</div>
					<div style={localStyles.divButtons}>
						<button type='button' onClick={this.tryAgain} >
							{resource.buttonTryAgain}
						</button>
					</div>
				</React.Fragment>
			);
		} else {
			var divWaiting = this.state.modalMode == MODAL_MODE_WAIT ?
				(<div style={localStyles.divWaiting}>{resource.waiting}</div>) : null;  
			var optionsHtml = formatList.map( (format, index) => {
				return (<option key={index} value={format.value}>{format.label}</option>);
			} );
			var modalBodyHtml = (
				<React.Fragment>
					<div style={localStyles.divParameters}>
						<div>
							{resource.modallabelFile} 
							<input type="text" name="filename" style={{marginLeft: '10px', width: '400px'}}
								value={this.state.fileName} onChange={ this.handleInputEdit}
							/>
						</div>
						<div style={{ display: "flex", alignItems: 'center', marginTop: '20px' }}>
							<div>{resource.lLabelFormat}</div>
							<div style={{ marginLeft: "10px"}}>
								<select value={this.state.format} onChange={this.handleFormatSelectClick}>
									{optionsHtml}
								</select>
							</div>
		 				</div>
					</div>
					{divWaiting}
					<div style={localStyles.divButtons}>
						<button type='button' title={resource.buttonExportTooltip}
							onClick={this.startExport} disabled={this.state.modalMode == MODAL_MODE_WAIT}
						>
							{resource.buttonExport}
						</button>
					</div>
				</React.Fragment>
			);
		}
		return (
			<Modal 
				open={this.props.isModalOpen} onClose={this.props.close} center 
				styles={styleModal} closeOnOverlayClick={false} animationDuration={1}
			>
				<div>
					<div style={localStyles.divHeader}>
						{resource.modalExportTitle}
					</div>
					{modalBodyHtml}
				</div>
			</Modal>
		);
	}
}

class ImportModal extends React.Component {

	constructor(props) {
		super(props);
		this.resource = props.page.resource;
		this.fileImportRef = React.createRef();
		this.file = null;
		this.resultMessage = '';
		this.resultLog = '';
		this.state = {
			modalMode: MODAL_MODE_ACTIVE,
			fileName: this.resource.textSelectFile,
			format: "l3",
		}
	}
	initModal = () => {
		this.file = null;
		this.resultMessage = '';
		this.resultLog = '';
		this.setState({
			modalMode: MODAL_MODE_ACTIVE,
			fileName: this.resource.textSelectFile,
			format: "l3",
		});
	}
	tryAgain = () => {
		this.setState({	modalMode: MODAL_MODE_ACTIVE});
	}

	handleFileSelect = (e) => {
		var files = e.target.files;
		if( files && files.length > 0 ) {
			this.file = files[0];
			this.setState({fileName: this.file.name})
		}
	}	
	handleFormatSelectClick = (e) => {
		this.setState({format: e.target.value});
	}

	startImport = async () => {
		this.setState({modalMode: MODAL_MODE_WAIT})
		const form = new FormData();
		form.append('format', this.state.format);
		form.append('file', this.file);
		var response = await performApiRequestForm(ACTION_BOOK_IMPORT_API_URL, form);
		if( response.success ) {
			this.resultMessage = response.message;
			this.resultLog = response.log;
			if( this.resultMessage == '' ) {
				this.resultMessage = this.resource.messageImportComplete;
			} 
		} else {
			this.resultMessage = response.message; 
			this.resultLog = response.log;
		}
		this.setState({modalMode: MODAL_MODE_COMPLETE})
	}

	render() {
		var resource = this.resource;
		if( this.state.modalMode == MODAL_MODE_COMPLETE ) {
			var divAdditionalMessage = null;
			if( this.resultLog ) {
				var divAdditionalMessage = (
					<div style={{marginLeft: 'auto', marginRight: 'auto', marginBottom: '30px', width: '95%'}}>
						<textarea style={{width: '100%'}} rows="8" readOnly={true}>
							{this.resultLog}
						</textarea>
					</div>		
				);
			}
			var modalBodyHtml = (
				<React.Fragment>
					<div style={{color: 'red', textAlign: 'center', padding: '30px', fontSize: '16pt'}} 
						dangerouslySetInnerHTML={{__html: this.resultMessage}}
					/>
					{divAdditionalMessage}
					<div style={localStyles.divButtons}>
						<button type='button' onClick={this.tryAgain} >
							{resource.buttonTryAgain}
						</button>
					</div>
				</React.Fragment>
			);
		} else {
			var buttonImportDisabled = this.state.modalMode == MODAL_MODE_WAIT || this.file == null;
			var divWaiting = this.state.modalMode == MODAL_MODE_WAIT ?
				(<div style={localStyles.divWaiting}>{resource.waiting}</div>) : null;  
			var optionsHtml = formatList.map( (format, index) => {
				return (<option key={index} value={format.value}>{format.label}</option>);
			} );
			var modalBodyHtml = (
				<div style={{margin: '15px'}}>
					<div>
						{resource.labelFile}: 
						<span style={{marginLeft: '5px', marginRight: '10px'}}>{this.state.fileName}</span> 
						<button onClick={e => this.fileImportRef.current.click()}>...</button>
						<input ref={this.fileImportRef} type={'file'}  style={{display: 'none'}} 
							onChange={e => this.handleFileSelect(e)} 
						/>
					</div>
					<div style={{ display: "flex", alignItems: 'center', marginTop: '20px' }}>
						<div>{this.resource.labelFormat}</div>
						<div style={{ marginLeft: "10px"}}>
							<select value={this.state.format} onChange={this.handleFormatSelectClick}>
								{optionsHtml}
							</select>
						</div>
	 				</div>
					{divWaiting}
					<div style={localStyles.divButtons}>
						<button type='button' title={resource.buttonImportTooltip}
							onClick={this.startImport} disabled={buttonImportDisabled}
						>
							{resource.buttonImport}
						</button>
					</div>
				</div>
			);
		}
		return (
			<Modal 
				open={this.props.isModalOpen} onClose={this.props.close} center 
				styles={styleModal} closeOnOverlayClick={false} animationDuration={1} 
			>
				<div>
					<div style={localStyles.divHeader}>
						{this.resource.modalImportTitle}
					</div>
					{modalBodyHtml}
				</div>
			</Modal>
		);
	}
}

function ButtonImport(props) {
	if( canPerformAction( ACTION_BOOK_IMPORT ) )
		return (
			<React.Fragment>
				&nbsp;<button type='button' onClick={props.holder.importBook} title={props.holder.resource.buttonImportTooltip}>{props.holder.resource.buttonImport}</button>
			</React.Fragment>
		);
	else
		return (null);
} 
function ButtonExport(props) {
	if( canPerformAction( ACTION_BOOK_EXPORT ) )
		return (
			<React.Fragment>
				&nbsp;<button type='button' onClick={props.holder.exportBook} title={props.holder.resource.buttonExportTooltip}>{props.holder.resource.buttonExport}</button>
			</React.Fragment>
		);
	else
		return (null);
} 

function ControlPanelButtonExport(props) {
	
	if( canPerformAction( ACTION_BOOK_EXPORT ) ) {
		return (
			<button type='button' style={{marginLeft: '5px', width: '36px', height: '30px'}}
				 onClick={props.holder.openExportModal}
				 title={props.holder.resource.buttonExportTooltip} 
			>
				<img src={iconExport} />
			</button>			
		);
	} else {
		return null;
	}
}

function ControlPanelButtonImport(props) {
	
	if( canPerformAction( ACTION_BOOK_IMPORT ) ) {
		return (
			<button type='button' style={{marginLeft: '5px', width: '36px', height: '30px'}}
				 onClick={props.holder.openImportModal}
				 title={props.holder.resource.buttonImportTooltip} 
			>
				<img src={iconImport} />
			</button>			
		);
	} else {
		return null;
	}
}

const styleModal = {
	modal: {
		background: '#ffffff',
		padding: '0px',
		borderRadius: '21px',
		maxWidth: '500px',
		width: '100%',
	},
	closeButton: {
		top: '10px',
		right: '10px',
		fill: '#FF0000',
		outline: 'none',
	},
};

const localStyles = {
	divHeader: {
		fontSize: '16pt',
		textAlign: 'center',
		padding: '10px',
		borderRadius: '21px 21px 0 0',
		backgroundColor: '#F0F0F0'
	},
	divParameters: {
		marginTop: '10px',
		padding: '10px'
	},
	divWaiting: {
		textAlign: 'center',
		padding: '7px'
	},
	divButtons: {
		marginTop: '7px',
		padding: '10px',
		marginRight: '15px',
		textAlign: 'right'
	}
};

const formatList = [
	{ value: "ubr", label: "UBR XML" },
	//{ value: "l3", label: "USH XML" },
	//{ value: "dc", label: "Dublin core" },
	//{ value: "bibtex", label: "Bibtex" },
	//{ value: "marc21", label: "MARC21" },
	//{ value: "unimarc", label: "UNIMARC" },
	//{ value: "marc21xml", label: "MARC21-XML" },
	//{ value: "unimarcxml", label: "UNIMARC-XML" },
	//{ value: "marc21txt", label: "MARC21-TXT" },
	//{ value: "unimarctxt", label: "UNIMARC-TXT" }
];

export default BookListPage;
