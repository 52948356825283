const ResourceUkr = {
	App: {
		msgAccessDenied: 'Вибачте. Немає прав для доступу до даного ресурсу'
	},
	components: {
		exim: {
			ClassifierImport: {
				buttonImport: "Імпортувати",
				buttonImportTooltip: "Імпортувати"
			},
			OnixExport: {
				buttonExport: "Експорт в ONIX",
				buttonExportTooltip: "Експортувати позначені повідомлення в форматі Onix",
				msgSelectDataForExport: 'Необхідно вибрати дані для експорту'			
			},
			OnixImport: {
				buttonImport: "Імпорт з ONIX",
				buttonImportTooltip: "Імпортувати повідомлення в форматі Onix",
			}
		},
		footer: {
			title: 'Український інститут книги'
		},
		fulldescription: {
			FieldDateTime: {
			},
			FieldDecimal: {
			},
			FieldEnumeration: {
				labelUndefined: '<Не визначено>'
			},
			FieldFile: {
				buttonSelectFile: 'Вибрати файл для збереження',
				buttonDownloadFile: 'Завантажити збережений файл на локальний пристрій',
				buttonDownloadFileAfterSave: 'Файл буде доступним після збереження',
				buttonClearFile: 'Очистити файл'
			},
			FieldGroup: {
				buttonCollapse: 'Згорнути',
				buttonCollapseTooltip: 'Згорнути групу полів',
				buttonExpand: 'Розгорнути',
				buttonExpandTooltip: 'Розгорнути групу полів',
				buttonGoToRefTooltip: 'Перейти',
				buttonSelectTooltip: 'Пов\'язати і скопіювати поля',
				buttonLinkTooltip: 'Пов\'язати',
				buttonClearTooltip: 'Очистити посилання',
				buttonSave: 'Записати',
				buttonSaveTooltip: 'Записати Занести обʼєкт до бази даних'
			},
			FieldImage: {
				buttonClear: 'Очистити',
				buttonClearTooltip: 'Очистити поле зображення',
				buttonSelect: 'Вибрати',
				buttonSelectTooltip: 'Вибрати зображення з файлу'
			},
			FieldInteger: {
			},
			FieldList: {
				buttonAdd: 'Додати',
				buttonAddTooltip: 'Додати обʼєкт даного типу',
				buttonCollapse: 'Згорнути',
				buttonCollapseTooltip: 'Згорнути множину полів',
				buttonDelete: 'Вилучити',
				buttonDeleteTooltip: 'Вилучити поле',
				buttonExpand: 'Розгорнути',
				buttonExpandTooltip: 'Розгорнути множину полів',
				buttonMoveDown: 'Опустити',
				buttonMoveDownTooltip: 'Опустити',
				buttonMoveUp: 'Підняти',
				buttonMoveUpTooltip: 'Підняти'
			},
			FieldReference: {
				buttonClearTooltip: 'Очистити посилання',
				buttonSelect: 'Вибрати',
				buttonSelectTooltip: 'Вибрати обʼєкт, на який буде створено посилання',
				messageNotDefined: 'Не визначено'
			},
			FieldString: {
				buttonGo: 'Перейти',
				buttonGoTooltip: 'Перейти за посиланням'
			},
			PlainFullDescription: {
				messageSaved: 'Успішно записано',
				messageInitForm: 'Активація форми...',
				messageInstanceIsAbsent: 'Вибачте, об`єкт відсутній в базі даних'
			},
			SystemData: {
				labelCard: 'Картка', 
				labelClass: 'Клас', 
				labelOwnerGroup: 'Власник (група)', 
				labelOwnerUser: 'Власник (користувач)',
				labelCreateUser: 'Хто створив', 
				labelCreateTime: 'Час створення', 
				labelModifyUser: 'Хто змінив', 
				labelModifyTime: 'Час зміни',
				title: 'Системні дані'
			}
		},
		header: {
			Header: {
				title: 'Автоматизована інформаційна система "Книжки на ринку"',
				
				msgInvalidLoginPassword: 'Невірний логін та/або пароль',
				
				buttonLogin: 'Авторизація',
				buttonLogout: 'Вийти',
				buttonProfile: 'Профіль',
				buttonProfileTooltip: 'Профіль'
			}
		},
		login: {
			LoginForm: {
				title: 'Реєстрація в системі',
				fieldNameLabel: 'Користувач',
				fieldNamePlaceholder: 'Користувач',
				fieldPasswordLabel: 'Пароль',
				fieldPasswordPlaceholder: 'Пароль',
				buttonSubmit: 'Вхід',
				buttonCancel: 'Відмінити'
			}
		},
		message: {
			ConfirmMessageModal: {
				header: 'Увага',
				buttonYes: 'Так',
				buttonNo: 'Ні'
			},
			InfoMessage: {
				defaultNotSuccessMessage: 'В процесі виконання операції виникла помилка'
			}
		},
		navigation: {
			ButtonMode: {
				titleIcon: 'Малюнок',
				titleText: 'Текст'
			},
			NavBar: {
				menuCatalog: 'Каталог',
				menuProducts: 'Продукти',
				menuSubjects: 'Суб`єкти',
				menuEBook: 'єКнига',
				menuAdmin: 'Адміністрування',
				formDbmanagerTitle: 'Налаштування БД',
				formSystemInfoTitle: 'Системна інформація',
				formMainTitle: 'Головна',
				formProfileTitle: 'Профіль',
				tfAuthorityAuthorTitle: 'Автори',
				tfAuthorityPublisherTitle: 'Бренди',
				tfBookTitle: 'Книги',
				tfConfirmationTitle: 'Супровідні документи',
				tfContactTitle: 'Контакти',
				tfDerivativeTitle: 'Екранізації',
				tfDigitalStoreTitle: 'Сховища',
				tfEstimationTitle: 'Оцінки експертів',
				tfEventTitle: 'Події',
				tfHonorTitle: 'Нагороди',
				tfLibraryTitle: 'Бібліотеки',
				tfMessageTitle: 'Повідомлення',
				tfOfferTitle: 'Пропозиції',
				tfOrderTitle: 'Обране',
				tfOrganizationTitle: 'Організації',
				tfLocationTitle: 'Локації',
				tfProcurementTitle: 'Закупівлі',
				tfProductTitle: 'Продукти',
				tfProviderTitle: 'Постачальники',
				tfTopicTitle: 'Класифікатори',
				tfUserGroupTitle: 'Групи користувачів',
				tfUserTitle: 'Користувачі',
				tfEBookBankTitle: 'єКнига Оплати',
				tfEBookReportTitle: 'єКнига Звіти',
				tfEBookReportItemTitle: 'єКнига Рядки звіту',
				tfEBookSupplierTitle: 'єКнига Постачальники',
				tfEBookTerminalTitle: 'єКнига Термінали',
			}
		},
		plain: {
			FieldEnum: {
				enumNotDefined: 'Не визначено'
			},
			FieldList: {
				labelEmptyTable: 'Немає значень'
			},
			FieldList_Enum: {
				labelEmptyTable: 'Немає значень'
			},
			FieldList_MultilangString: {
				enumNotDefined: 'Не визначено',
				labelEmptyTable: 'Немає значень'
			},
			FieldList_Reference: {
				labelEmptyTable: 'Немає значень'
			},
			FieldList_TableGroup: {
				buttonAdd: 'Додати новий рядок',
				buttonClone: 'Додати рядок за зразком'
			},
			FieldReference: {
				buttonClearTooltip: 'Очистити посилання',
				buttonSelect: 'Вибрати',
				buttonSelectTooltip: 'Вибрати обʼєкт, на який буде створено посилання',
				messageNotDefined: 'Не визначено'
			},
			FieldString: {
				buttonGoToUrl: 'Перейти',
				buttonGoToUrlTooltip: 'Перейти за посиланням',
			},
			PlainPage: {
				dlgTitle: 'Збереження',
				msgActivateForm: 'Активація форми...',
				buttonSave: 'Записати',
				msgPleaseWait: 'Будь-ласка, зачекайте',
				msgSaved: 'Успішно збережено',
				labelCbSystemData: 'Системні дані',
				labelSystemData: 'Системні дані',
				msgFetching: 'Завантаження...',
				labelCard: 'Картка', 
				labelClass: 'Клас', 
				labelOwnerGroup: 'Власник (група)', 
				labelOwnerUser: 'Власник (користувач)',
				labelCreateUser: 'Хто створив', 
				labelCreateTime: 'Час створення', 
				labelModifyUser: 'Хто змінив', 
				labelModifyTime: 'Час зміни',
			}
		},
		rateview: {
			button: {
				ButtonUshExport: {
					buttonExport: "Експортувати",
					buttonExportTooltip: "Експортувати позначені обʼєкти в форматі USH XML",
					msgSelectDataForExport: 'Необхідно вибрати дані для експорту'			
				},
				ButtonUshImport: {
					buttonImport: "Імпортувати",
					buttonImportTooltip: "Імпортувати обʼєкти в форматі USH XML"
				}
			},
			conditions: {
				conditions: {
					none: {
						title: 'Без обмежень'
					},
					isNotNull: {
						title: 'Визначено'
					},
					isNull: {
						title: 'Не визначено'
					},
					include: {
						title: 'Включаючи'
					},
					notInclude: {
						title: 'За винятком'
					},
					startsWith: {
						title: 'Починається з'
					},
					notStartsWith: {
						title: 'Не починається з'
					},
					equals: {
						title: 'Дорівнює'
					},
					notEquals: {
						title: 'Не дорівнює'
					},
					containsText: {
						title: 'Містить текст'
					},
					notContainsText: {
						title: 'Не містить текст'
					},
					containsAllWords: {
						title: 'Містить усі слова'
					},
					containsAnyWord: {
						title: 'Містить хоча б одне слово'
					},
					greater: {
						title: 'Більше'
					},
					greaterEquals: {
						title: 'Більше або дорівнює'
					},
					less: {
						title: 'Менше'
					},
					lessEquals: {
						title: 'Менше або дорівнює'
					},
					between: {
						title: 'В діапазоні'
					},
					notBetween: {
						title: 'За межами діапазону'
					},
					monthInclude: {
						title: 'Місяць включаючи'
					},
				},
				ActiveFilterList: {
					buttonClear: 'Очистити',
					buttonClearTooltip: 'Очистити позначений фільтр',
					labelActiveFilterList: 'Встановлені умови вибору'
				},
				ButtonViewConditions: {
					title: 'Умови',
					tooltip: 'Налаштувати додаткові умови перегляду'
				}, 
				FilterBoolean: {
					labelYes: 'Так'
				},
				TabRowColumns: {
					labelRowsOnPage: 'Рядків на сторінці',
					colTitleColumn: 'Стовпець',
					colTitleVisible: 'Показувати',
					colTitleSum: 'Сума',
					colTitleAvg: 'Середнє',
					colTitleMin: 'Мінімум',
					colTitleMax: 'Максимум',
					buttonMoveUp: 'Підняти',
					buttonMoveDown: 'Опустити'		
				},
				TabSorting: {
					labelColumns: 'Стовпчики',
					labelSorting: 'Сортувати за',
					buttonMoveUp: 'Підняти',
					buttonMoveDown: 'Опустити',
					rbAscending: 'За зростанням',
					rbDescending: 'За спаданням'
				},
				ViewConditionsModal: {
					header: 'Умови перегляду',
					tabRowsColumns: 'Рядки та стовпці',
					tabFilters: 'Фільтри',
					tabSorting: 'Сортування',
					buttonOk: 'Ок',
					buttonCancel: 'Відмінити'
				}
			},
			filter: {
				FilterEnumField: {
					noCondition: '<Без обмежень>'
				},
				FilterObjectRef: {
					buttonClearTooltip: 'Очистити значення фільтру'
				},
				FilterOwnEntities : {
					title: 'Власні'
				},
				RateViewFilters: {
					title: 'Фільтри',
					buttonClear: 'Очистити',
					buttonClearTooltip: 'Очистити значення всіх фільтрів (умов вибору)'
				}
			},
			modal: {
				UpdateFieldModal: {
					title: 'Змінити об`єкти',
					msgPleaseWait: 'Будь-ласка, зачекайте ...',
					labelChange: 'Змінити',
					optionMarked: 'Позначені',
					optionSelected: 'Відібрані',
					labelRebuild: 'Перебудувати:',
					cbObjects: 'Об`єкти',
					cbDownloadImages: 'завантажувати малюнки',
					cbCards: 'Картки',
					cbWords: 'Словники',
					cbLinks: 'Зв`язки',
					cbFiles: 'Файли',
					columnField: 'Поле',
					columnLang: 'Мова',
					columnValue: 'Значення',
					columnClear: 'Очистити',
					columnOnlyEmpty: 'Тільки незаповнені',
					labelNewValue: 'Нове значення',
					buttonAdd: 'Додати',
					buttonDelete: 'Вилучити',
					buttonUpdate: 'Змінити',
					buttonSelectTooltip: 'Вибрати обʼєкт, на який буде створено посилання',
					buttonClearTooltip: 'Очистити посилання',
					messageNotDefined: 'Не визначено'
				},
				UshExportModal: {
					title: 'Експорт',
					waiting: 'Будь-ласка, зачекайте ...',
					file: 'Файл',
					buttonExport: 'Експортувати',
					buttonExportTooltip: 'Експортувати позначені обʼєкти в xml файл',
					msgInternalError: 'Внутрішня помилка на сервері',
					error: 'Помилка'
				},
				UshImportModal: {
					noFileSelected: 'Файл не вибрано',
					title: 'Імпорт',
					waiting: 'Будь-ласка, зачекайте ...',
					file: 'Файл',
					buttonImport: 'Імпортувати',
					buttonImportTooltip: 'Імпортувати обʼєкти з xml файлу'
				}
			},
			ButtonChangeOwner: {
				buttonChangeOwner: 'Змінити власника',
				buttonChangeOwnerTooltip: 'Змінити власника для позначених об`єктів',
				msgNoRowSelected: 'Не позначено жодного рядка',
				msgOwnerChanged: 'Власника змінено'
			},
			ButtonFieldUpdate: {
				buttonUpdateField: 'Змінити поле',
				buttonUpdateFieldTooltip: 'Змінити значення поля для позначених/відібраних об`єктів',
				msgUpdated: 'Успішно змінено',
			},
			ButtonGlobalIdUpdate: {
				buttonUpdateGlobalId: 'Змінити глобальний ідентифікатор',
				buttonUpdateGlobalIdTooltip: 'Змінити значення глобальних ідентифікаторів для позначених об`єктів',
				msgUpdated: 'Успішно змінено',
			},
			ButtonMergeInstances: {
				buttonMergeInstances: 'Злити об`єкти',
				buttonMergeInstancesTooltip: 'Злити об`єкти',
				msgSelectAtLeast2: 'Виберіть принаймні 2 об`єкти',
				msgMerged: 'Об`єкти успішно злито'
			},
			RateView: {
				msgSetConditionsAndRefresh: 'Задайте умови перегляду та натисніть кнопку \"Відновити\"',
				msgLoading: 'Йде завантаження даних...', 
				msgError: 'Під час виконання запиту виникла нештатна ситуація', 
				msgInitForm: 'Активація форми...',
				msgConfirmDeleteMarked: 'Вилучити позначені об`єкти?',
				
				labelViewConditions: 'Умови перегляду',
				labelPageSize: 'Рядків',
				labelPageNum: 'Сторінка',
				labelRecordCount: 'Записів',
				
				buttonParameters: 'Параметри',
				buttonParametersTooltip: 'Параметри',
				buttonRefresh: 'Відновити',
				buttonRefreshTooltip: 'Відновити вміст табличної форми',
				buttonFirstPage: 'Перша',
				buttonFirstPageTooltip: 'Перейти до першої сторінки',
				buttonPrevPage: 'Попередня',
				buttonPrevPageTooltip: 'Перейти до попередньої сторінки',
				buttonExplicitPage: 'Перейти',
				buttonExplicitPageTooltip: 'Перейти до сторінки зі вказаним номером',
				buttonNextPage: 'Наступна',
				buttonNextPageTooltip: 'Перейти до наступної сторінки',
				buttonLastPage: 'Остання',
				buttonLastPageTooltip: 'Перейти до останньої сторінки',
				buttonAddInstance: 'Додати',
				buttonAddInstanceTooltip: 'Створити новий обʼєкт',
				buttonEditInstance: 'Редагувати',
				buttonEditInstanceTooltip: 'Редагувати позначений обʼєкт',
				buttonDeleteInstances: 'Вилучити',
				buttonDeleteInstancesTooltip: 'Вилучити позначені обʼєкти',
				buttonSelect: 'Вибрати',
				buttonSelectTooltip: 'Вибрати',
				buttonTransition: 'Перейти',
				buttonTransitionTooltip: 'Перейти до іншої форми',
				buttonHtml: 'HTML',
				buttonHtmlTooltip: 'Переглянути у відповідності до обраного шаблону',
				buttonPdf: 'PDF',
				buttonPdfTooltip: 'Для позначених обʼєктів створити файл в форматі PDF',
				buttonXml: 'XML',
				buttonXmlTooltip: 'Для позначених обʼєктів створити файл в форматі docx для подальшого редагування',

				menuHideLeftSide: 'Сховати панель "Умови перегляду"',
				menuShowLeftSide: 'Показати панель "Умови перегляду"',
				optionModeTable: 'Таблиця',
				optionModeTree: 'Ієрархія',
			}
		},
		tableview: {
			TableView: {
				aggregationSum: 'Сума',
				aggregationAvg: 'Середнє',
				aggregationMin: 'Мінімум',
				aggregationMax: 'Максимум',
			}
		},
		treeview: {
			TreeView: {
				waiting: 'Будь-ласка, зачекайте ...',
			},
			TreeNode: {
				buttonCollapse: 'Згорнути',
				buttonCollapseTooltip: 'Згорнути групу полів',
				buttonExpand: 'Розгорнути',
				buttonExpandTooltip: 'Розгорнути групу полів',
				waiting: 'Будь-ласка, зачекайте ...',
			}
		}
	},
	modal: {
		exim: {
			ClassifierImportModal: {
				title: 'Імпорт класифікатора',
				labelType: 'Тип',
				labelClassifier: 'Класифікатор',
				labelFile: 'Файл',
				buttonImport: 'Імпортувати',
				buttonImportTooltip: 'Імпортувати класифікатор з файлу обраного стандарту',
				msgSuccess: 'Успішно імпортовано',
				waiting: 'Будь-ласка, зачекайте ...',
			},
			OnixExportModal: {
				title: 'Експорт',
				waiting: 'Будь-ласка, зачекайте ...',
				file: 'Файл',
				buttonExport: 'Експортувати',
				buttonExportTooltip: 'Експортувати позначені повідомлення в форматі Onix',
				msgInternalError: 'Внутрішня помилка на сервері',
				error: 'Помилка'
			},
			OnixImportModal: {
				title: 'Імпорт з ONIX',
				optionNetworkImport: 'Мережевий імпорт ( HTTP )',
				optionLocalImport: 'Локальний імпорт',
				optionElibriImport: 'Імпорт з Elibri',
				labelFile: 'Файл',
				labelSaveOriginal: 'Зберегти оригінал',
				labelDownloadImages: 'Завантажувати зображення',
				labelIgnoreInvalid: 'Ігнорувати помилкові',
				labelIgnoreWarning: 'Не видавати попередження',
				labelTestMode: 'Тестовий режим (не зберігати записи)',
				buttonSelectFile: 'Вибрати...',
				buttonImport: 'Імпортувати',
				buttonImportTooltip: 'Імпортувати повідомлення в форматі Onix',
				buttonRepeat: 'Повторити імпорт',
				buttonRepeatTooltip: 'Повторити імпорт з файлу в форматі Onix',
				msgSuccess: 'Успішно імпортовано',
				waiting: 'Будь-ласка, зачекайте ...',
			}
		},
		exportbook: {
			ExportBook: {
				title: 'Експорт книг',
				waiting: 'Будь-ласка, зачекайте ...',
				file: 'Файл',
				format: 'Формат',
				buttonExport: 'Експортувати',
				buttonExportTooltip: 'Експортувати позначені бібліографічні описи документів в файл обраного стандарту',
				msgInternalError: 'Внутрішня помилка на сервері',
				error: 'Помилка'
			}
		},
		importbook: {
			ImportBook: {
				noFileSelected: 'Файл не вибрано',
				title: 'Імпорт книг',
				waiting: 'Будь-ласка, зачекайте ...',
				file: 'Файл',
				format: 'Формат',
				buttonImport: 'Імпортувати',
				buttonImportTooltip: 'Імпортувати бібліографічні описи документів з файлу обраного стандарту'
			}
		},
		importonix: {
			ImportOnix: {
				noFileSelected: 'Файл не вибрано',
				title: 'Імпорт ',
				waiting: 'Будь-ласка, зачекайте ...',
				file: 'Файл',
				buttonImport: 'Імпортувати',
				buttonImportTooltip: 'Імпортувати повідомлення з файлу стандарту Onix',
				msgSuccess: 'Успішно імпортовано'
			}
		},
		message: {
			Message : {
				header: 'Повідомлення'
			}
		},
		rateview: {
			ChangeOwnerModal: {
				title: 'Змінити власника',
				labelGroup: 'Група',
				labelUser: 'Користувач',
				cbOnlySelectedGroup: 'Тільки з позначеної групи',
				buttonChange: 'Змінити',
				msgPleaseWait: 'Будь-ласка, зачекайте ...'
			},
			MergeInstancesModal: {
				title: 'Злити об`єкти',
				msgPleaseWait: 'Будь-ласка, зачекайте ...',
				tdCardMaster: 'Картка',
				tdOwnerUser: 'Власник',
				tdOwnerGroup: 'Група',
				tdCreateUser: 'Створив',
				tdCreateDate: 'Час створення',
				tdModifyUser: 'Змінив',
				tdModifyDate: 'Час зміни',
				buttonMoveUp: 'Підняти',
				buttonMoveDown: 'Опустити',
				buttonMerge: 'Злити',
			},
			UpdateGlobalIdModal: {
				title: 'Змінити глобальний ідентифікатор',
				msgPleaseWait: 'Будь-ласка, зачекайте ...',
				labelNewValue: 'Нове значення',
				buttonGenerate: 'Генерувати',
				buttonUpdate: 'Змінити'
			}
		},
		selectinstance: {
		}
	},
	pages: {
		dbmanager: {
			DbManagerPage: {
				title: 'Керування базою даних',
				labelWait: 'Будь-ласка, зачекайте ...',
				buttonPerform: 'Виконати',
				tabCommon: 'Загальне',
				tabObjects: 'Об`єкти',
				tabCards: 'Картки',
				tabLinks: 'Зв`язки',
				tabFiles: 'Файли',
				tabWords: 'Словники',
				cbResolveReferences: 'Розв`язати посилання',
				cbDeleteNotUsedFiles: 'Вилучити файли, що не використовуються',
				labelPatch: 'Патч',
				labelRebuildObjects: 'Перебудувати об`єкти',
				cbDownloadImages: 'Завантажувати малюнки',
				labelRebuildCards: 'Перебудувати картки',
				labelRebuildLinks: 'Перебудувати зв`язки',
				labelRebuildFiles: 'Перебудувати файли',
				labelRebuildWords: 'Перебудувати словники',
			}
		},
		plain: {
			AuthorityAuthor: {
				title: 'Автор'
			},
			AuthorityPublisher: {
				title: 'Бренд (видавництво)'
			},
			Book: {
				title: 'Книга'
			},
			Classifier: {
				title: 'Класифікатор'
			},
			Confirmation: {
				title: 'Супровідний документ'
			},
			Contact: {
				title: 'Контакт'
			},
			Derivative: {
				title: 'Екранізація'
			},
			DigitalStore: {
				title: 'Сховище'
			},
			Estimation: {
				title: 'Оцінка'
			},
			Event: {
				title: 'Подія'
			},
			Honor: {
				title: 'Нагорода'
			},
			Library: {
				title: 'Бібліотека'
			},
			Location: {
				title: 'Локація'
			},
			Message: {
				title: 'Повідомлення',
				titleProducts: 'Продукти',
				buttonSelect: 'Вибрати',
				buttonSelectTooltip: 'Вибрати продукт і включити до складу повідомлення'
			},
			Offer: {
				title: 'Пропозиція'
			},
			Order: {
				title: 'Замовлення'
			},
			Organization: {
				title: 'Організація'
			},
			PlainEBookReportPage: {
				msgSelectSupplier: 'Необхідно вказати постачальника',
				buttonAdd: 'Додати рядок звіту',
				buttonClone: 'Додати рядок звіту за зразком'
			},
			Procurement: {
				title: 'Закупівля'
			},
			Product: {
				title: 'Продукт'
			},
			Provider: {
				title: 'Постачальник'
			},
			Topic: {
				title: 'Тема'
			},
			UserGroup: {
				title: 'Група користувачів'
			},
			User: {
				title: 'Користувач'
			}
		},
		profile: {
			ProfilePage: {
				buttonChange: 'Змінити', 
				buttonRefresh: 'Відновити',

				colHeaderClass: 'Клас',
				colHeaderCreate: 'створювати',
				colHeaderDelete: 'вилучати',
				colHeaderRead: 'читати',
				colHeaderUpdate: 'змінювати',

				labelAccessLevel: 'Права доступу',
				labelAccess: 'Доступ',
				labelActions: 'Дії',
				labelChangePassword: 'Змінити пароль',
				labelForms: 'Форми',
				labelGroups: 'Групи',
				labelLogin: 'Логін',
				labelName: 'Ім`я',
				labelUser: 'Користувач',
				labelOrganization: 'Організація',
				labelNewPassword: 'Новий пароль',
				labelObjects: 'Об`єкти',
				labelOldPassword: 'Старий пароль',
				labelLanguage: 'Мова інтерфейсу', 
				labelButtonView: 'Вигляд кнопок',
				
				msgPasswordChanged: 'Пароль змінено',
				
				permissionAll: 'всі',
				permissionGroup: 'група',
				permissionOwn: 'власні',
				permissionCreate: 'створювати', 
				permissionReadAll: 'читати всі',
				permissionReadGroup: 'читати групові',
				permissionReadOwn: 'читати власні',
				permissionUpdateAll: 'змінювати всі',
				permissionUpdateGroup: 'змінювати групові',
				permissionUpdateOwn: 'змінювати власні',
				permissionDeleteAll: 'вилучати всі',
				permissionDeleteGroup: 'вилучати групові',
				permissionDeleteOwn: 'вилучати власні',
			}
		},
		tableform: {
			AuthorityAuthorList: {
				formTitle: 'Автори',
				modalExportTitle: 'Експорт',
				waiting: 'Будь-ласка, зачекайте ...',
				modalExportLabelFile: 'Файл',
				modalExportLabelFormat: 'Формат',
				buttonExport: 'Експортувати',
				buttonExportTooltip: 'Експортувати позначених авторів в файл обраного стандарту',
				buttonTryAgain: 'Повторити',
				msgInternalError: 'Внутрішня помилка на сервері',
				error: 'Помилка'
			},
			AuthorityPublisherList: {
				formTitle: 'Бренди',
				modalExportTitle: 'Експорт',
				waiting: 'Будь-ласка, зачекайте ...',
				modalExportLabelFile: 'Файл',
				modalExportLabelFormat: 'Формат',
				buttonExport: 'Експортувати',
				buttonExportTooltip: 'Експортувати позначені бренди в файл обраного стандарту',
				buttonTryAgain: 'Повторити',
				msgInternalError: 'Внутрішня помилка на сервері',
				error: 'Помилка'
			},
			BookList: {
				formTitle: 'Книги',
				buttonImport: 'Імпортувати',
				buttonImportTooltip: 'Імпортувати описи книг в обраному форматі',
				buttonExport: 'Експортувати',
				buttonExportTooltip: 'Експортувати позначені бібліографічні описи документів в файл обраного стандарту',
				modalExportTitle: 'Експорт книг',
				modalImportTitle: 'Імпорт книг',
				labelFile: 'Файл',
				labelFormat: 'Формат',
				textSelectFile: 'Необхідно вибрати файл',
				buttonTryAgain: 'Повторити',
				waiting: 'Будь-ласка, зачекайте ...',
				messageImportComplete: 'Успішно імпортовано',
			},
			ClassifierList: {
				formTitle: '',
				buttonImport: 'Імпортувати',
				buttonImportTooltip: 'Імпортувати обʼєкти з xml файлу'
			},
			ConfirmationList: {
				formTitle: 'Супровідні документи',
			},
			ContactList: {
				formTitle: 'Контакти',
			},
			DerivativeList: {
				formTitle: 'Екранізації',
			},
			DigitalStoreList: {
				formTitle: 'Сховища',
			},
			EBookBankList: {
				formTitle: 'єКнига Оплати',
				buttonImport: 'Імпортувати',
				buttonImportTooltip: 'Імпортувати звіти з формату XML',
				modalImportTitle: 'Імпорт звітів',
				labelFile: 'Файл',
				textSelectFile: 'Необхідно вибрати файл',
				buttonTryAgain: 'Повторити',
				waiting: 'Будь-ласка, зачекайте ...',
				messageImportComplete: 'Успішно імпортовано',
			},
			EBookReportList: {
				formTitle: 'єКнига Звіти',
				buttonImport: 'Імпортувати',
				buttonImportTooltip: 'Імпортувати звіти з формату XML',
				buttonExport: 'Експортувати',
				buttonExportTooltip: 'Експортувати звіти в формат XML',
				modalExportTitle: 'Експорт звітів',
				modalImportTitle: 'Імпорт звітів',
				labelFile: 'Файл',
				textSelectFile: 'Необхідно вибрати файл',
				buttonTryAgain: 'Повторити',
				waiting: 'Будь-ласка, зачекайте ...',
				messageImportComplete: 'Успішно імпортовано',
			},
			EBookReportItemList: { 
				formTitle: 'єКнига Рядки звіту',
			},
			EBookSupplierList: {
				formTitle: 'єКнига Постачальники',
				buttonExport: 'Експортувати',
				buttonExportTooltip: 'Експортувати позначені записи в файл обраного стандарту',
				modalExportTitle: 'Експорт постачальніків',
				labelFile: 'Файл',
				labelFormat: 'Формат',
				textSelectFile: 'Необхідно вибрати файл',
				buttonTryAgain: 'Повторити',
				waiting: 'Будь-ласка, зачекайте ...',
			},
			EBookTerminalList: { 
				formTitle: 'єКнига Термінали',
			},
			EstimationList: {
				formTitle: 'Оцінки експертів',
			},
			EventList: {
				formTitle: 'Події',
			},
			HonorList: {
				formTitle: 'Нагороди',
			},
			LibraryList: {
				formTitle: 'Бібліотеки',
			},
			LocationList: {
				formTitle: 'Локації',
			},
			MessageList: {
				formTitle: 'Повідомлення',
				buttonExport: 'Експортувати',
				buttonExportTooltip: "Експортувати позначені обʼєкти в форматі xml",
				buttonImport: 'Імпортувати',
				buttonImportTooltip: 'Імпортувати обʼєкти з xml файлу'
			},
			OfferList: {
				formTitle: 'Пропозиції',
				createOrder: 'До обраного',
				createProcurements: 'Створити закупівлі'
			},
			OrderList: {
				formTitle: 'Обране',
			},
			OrganizationList: {
				formTitle: 'Організації',
			},
			ProcurementList: {
				formTitle: 'Закупівлі',
			},
			ProductList: {
				formTitle: 'Продукти',
			},
			ProviderList: {
				formTitle: 'Постачальники',
				buttonImport: 'Імпортувати',
				buttonImportTooltip: 'Імпортувати обʼєкти з xml файлу'
			},
			TopicList: {
				formTitle: 'Класифікатори',
			},
			UserGroupList: {
				formTitle: 'Групи користувачів',
			},
			UserList: {
				formTitle: 'Користувачі',
			}
		}
	},
	util: {
		restapi: {
			msgNetworkError: 'Сервер недоступний (можливо, проблеми з інтернетом)'
		}
	}
}

export default ResourceUkr;
